<template>
<div class="d-flex flex-wrap justify-center">
  <h3 style="width:100%;" class="text-center">Kalender</h3>
 <Calendar small :calendar-height="300" :event-height="5"/>
</div>
</template>

<script>
import Calendar from "@/views/Calendar/Calendar";
export default {
  name: "KalenderKachel",
  components: {Calendar}
}
</script>

<style scoped>

</style>