import ApiService from "@/services/api.service";
import store from "@/store/index";
import {NotifiyClient} from "@/models/notification";
export const payroll = {
    namespaced: true,
    state: {
        all:[],
        requests:[],
        authenticatedUsers:[],
        countMine:0
    },
    actions: {
        countMine({commit}){
            return ApiService.get("payroll/count/mine").then(response => {
                commit("countMineSuccess",response.data)
            })
        },
        getRequests({commit}){
          return ApiService.get("user/get/payroll/request").then(response=>{
              commit("getRequestsSuccess",response.data)
          })
        },
        getAuthenticatedUsers({commit}){
          return ApiService.get("user/get/payroll/authenticated").then(response=>{
              commit("getAuthenticatedSuccess",response.data)
          })
        },

        getMyPayrolls({commit}){
          return ApiService.get("payroll/get/mine").then(response=>{
              commit("getMyPayrollsSuccess",response.data)
          })
        },
        bulkSave({commit}, payrolls) {
            return ApiService.post("payroll/save/bulk", payrolls).then(() => {

                store.dispatch("notificationsStore/add", new NotifiyClient("success", "Hochladen erfolgreich", false, null))
                commit("bulkSaveSuccess")

            }, error => {
                console.error(error);
                store.dispatch("notificationsStore/add", new NotifiyClient("error", "Formulare wurden nicht hochgeladen", false, "Bitte versuchen Sie es später erneut."))
                store.dispatch("loading/error");
            });
        }
    },
    mutations: {
        countMineSuccess(state,count){
            state.countMine=count;
        },
        getRequestsSuccess(state,users){
          state.requests=users;
        },
        getAuthenticatedSuccess(state,users){
          state.authenticatedUsers=users;
        },
        bulkSaveSuccess() {
            store.dispatch("loading/success");
        },
        getMyPayrollsSuccess(state,payrolls) {
            state.all=payrolls;
        }

    }
}