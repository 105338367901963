<template>
  <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-y
  >
    <v-card
        color="grey lighten-4"
        min-width="350px"
        flat
    >
      <v-toolbar
          dense
          :color="selectedEvent.color"
          dark
      >
        <v-toolbar-title style="font-size: 0.9rem;" v-html="selectedEvent.title"></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu
            v-model="gaesteAnzeigen"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
               icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon small>mdi-account-multiple</v-icon><span v-html="guestUsers.length"></span>
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item v-for="user of guestUsers" :key="user.id">
                <v-list-item-avatar>
                  <Avatar :file="user.profilePic" :name="user.firstName+' '+user.lastName"/>

                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="user.firstName+' '+user.lastName"></v-list-item-title>
                  <v-list-item-subtitle v-html="user.email"></v-list-item-subtitle>
                </v-list-item-content>

                <v-tooltip v-if="user.id===selectedEvent.userId" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs">mdi-crown-outline</v-icon>
                  </template>
                  <span>Host</span>
                </v-tooltip>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-menu v-if="host" v-model="showMenu"
                 :close-on-content-click="false"
                 :nudge-width="200"
                 offset-x>
          <template v-slot:activator="{ on, attrs }">
        <v-btn small icon v-on="on" v-bind="attrs">
          <v-icon small>mdi-dots-vertical</v-icon>
        </v-btn>
          </template>
          <v-list>
           <AddTermin @updateSelectedEvent="updateSelectedEvent" :edit-termin="selectedEvent" edit/>
          <v-list-item @click="deleteTermin" link>
            <v-list-item-avatar><v-icon>mdi-delete</v-icon></v-list-item-avatar>
            <v-list-item-title>Löschen</v-list-item-title>
          </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="1" class="d-flex justify-center">
            <v-icon small>mdi-clock-outline</v-icon>
          </v-col>
        <v-col>
          <div v-if="calDate(selectedEvent.start)===calDate(selectedEvent.end)">
            <span v-html="calDate(selectedEvent.start)"></span> <span> . </span><span v-html="calTimeDelta(selectedEvent.start,selectedEvent.end)"></span>
          </div>
          <div v-else>
            <span v-html="calStartAndEnd(selectedEvent.start,selectedEvent.end)"></span>
          </div>
        </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" class="d-flex justify-center">
            <v-icon small>mdi-text</v-icon>
          </v-col>
          <v-col>
            <div v-html="selectedEvent.beschreibung"></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" class="d-flex justify-center">
            <v-icon small>mdi-map-marker-outline</v-icon>
          </v-col>
          <v-col>
            <span v-html="selectedEvent.location"></span>
          </v-col>
        </v-row>
        <v-sheet color="primary" dark style="position: absolute;right:0;bottom:0;font-size: 0.6rem;" rounded class="px-1 ma-1" v-if="selectedEvent.type"><small v-html="selectedEvent.type"></small></v-sheet>

      </v-card-text>
      <v-card-actions>

      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import Avatar from "@/views/Avatar";
import moment from "moment";
import ApiService from "@/services/api.service";
import AddTermin from "@/views/Calendar/AddTermin";

export default {
  name: 'TerminDetails',
  components: {AddTermin, Avatar},
  data(){
    return{
      showMenu:false,
      guestUsers:[],
      gaesteAnzeigen:false,
      selectedOpenk:this.selectedOpenComp

    }
  },
  props: {
    host:Boolean,
    selectedElement: {},
    selectedEvent: {},
    selectedOpenProp: Boolean
  },
  computed:{

      selectedOpen: {
        get () { return this.selectedOpenProp },
        set (selectedOpenProp) { this.$emit('close', selectedOpenProp); }
      },

  },
  watch: {
    'selectedEvent.gaeste'() {
      this.getGuestUsersFromIds();
    }
  },
  created() {
    this.getGuestUsersFromIds();
  },
  methods:{
    updateSelectedEvent(event){
      this.$emit("updateSelectedEvent",event);
    },
    deleteTermin(){
      let self=this;
      ApiService.post("calendar/termin/delete",{content:this.selectedEvent.id}).then(()=>{
        self.$store.dispatch("calendar/getAllEvents");
      })
    },
    getGuestUsersFromIds(){
      let self=this;
      ApiService.post("user/getAllByIds",{ids:self.selectedEvent.gaeste}).then(response => {
        self.guestUsers=response.data;
      })
    },
    calStartAndEnd(start,end){
      return ""+moment(start).format('LLL')+" - "+moment(end).format('LLL');
    },
    calDate(date){

      return moment(date).format('ll');

    },
    calTime(time){
      return moment(time).format('LT');
    },
    calTimeDelta(start,end){
      return ""+moment(start).format('LT')+" - "+moment(end).format('LT');
    },
  }
}
</script>
