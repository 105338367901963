import ApiService from "@/services/api.service";
import store from "@/store/index";
import {NotifiyClient} from "@/models/notification";

export const forms = {
    namespaced: true,
    state: {
        all: [],
        allGroupedByCategory:[]
    },
    actions:{

        bulkSave({commit},forms){
            return ApiService.post("forms/save/bulk",forms).then(() => {

                store.dispatch("notificationsStore/add",new NotifiyClient("success","Hochladen erfolgreich",false,null))
                commit("bulkSaveSuccess")

            },error => {
                console.error(error);
                store.dispatch("notificationsStore/add",new NotifiyClient("error","Formulare wurden nicht hochgeladen",false,"Bitte versuchen Sie es später erneut."))

            });
        },
        getAll({commit}){
            return ApiService.get("forms/all").then(
                response => {
                    commit('getAllSuccess',response.data);
                },
                error =>{
                    console.error(error);
                    store.dispatch("notificationsStore/add",new NotifiyClient("warning","Fehler",false,"Formulare konnten nicht geladen werden."))
                });
        },
        getAllGroupedByCategory({commit}){
            return ApiService.get("forms/all/grouped/by/category").then(
                response => {
                    commit('getAllGroupedByCategorySuccess',response.data);
                },
                error =>{
                    console.error(error);
                    store.dispatch("notificationsStore/add",new NotifiyClient("warning","Fehler",false,"Formulare konnten nicht geladen werden."))
                });
        },
    },
    mutations: {
        getAllGroupedByCategorySuccess(state,data){
          state.allGroupedByCategory=data;
        },
        getAllSuccess(state,data){
            state.all=data;
        },
        bulkSaveSuccess(){
            console.log("Bulk Upload complete.")
        }

    }


}