

const initialState = {
    payrollTabs:{
        selected:0,
        items:["alle","anfragen"],
        searchQuery:""
    },
    calendar:{
        type:'month',
        eventFilter:'all',
        privateMode:false
    },
}

export const navigation = {
    namespaced: true,
    state: initialState,
    actions: {
        changeTab({commit},tab){
            commit("changeTab",tab);
        },
        changeType({commit}, type) {
            commit('changedType', type);
        },
    },
    mutations:{
        changeTab(state,tab){
            state.payrollTabs.selected=tab;
        },
        changedType(state, type) {
            state.calendar.type = type;
        },
    }
}