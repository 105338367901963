<template>
  <div class="d-flex justify-center flex-wrap">
<div style="width:250px;height:250px" class="d-flex justify-center flex-wrap">
  <video style="background-color:grey;webkit-transform: scaleX(-1);
  transform: scaleX(-1);" autoplay muted playsinline v-if="!imageData.image" width="100%" ref="video" class="camera-stream rounded" >Video not available on your Device :(</video>

  <img v-else :src="imageData.image" height="200px" style="-webkit-transform: scaleX(-1);
  transform: scaleX(-1);"  class="camera-stream rounded">
  <canvas style="display:none" ref="canvas" id="canvas"></canvas>

  <v-btn width="100%" v-if="!imageData.image" @click="captureImage" text class="mx-auto mt-5"><v-icon class="mr-1">mdi-camera</v-icon>Foto machen</v-btn>
  <div style="width:100%" v-else class="mt-5 d-flex justify-center"><v-btn @click="retake" :disabled="loading" text plain>Neues Foto</v-btn><v-btn @click="upload" :disabled="loading" :loading="loading"  color="success">Sieht gut aus!</v-btn></div>
</div>
  </div>
</template>

<script>
export default {
  name: "WebcamVideo",
  data() {
    return {
      mediaStream: null,
      imageData: {
        image: ''
      },
    }
  },
  computed:{
    loading(){
      return this.$store.state.loading.loadingState
    }
  },
  mounted() {
    this.startCamera();
  },

  beforeDestroy() {
   this.stopCamera();
  },
  methods:{
    stopCamera(){
      if(this.$refs.video) {
        this.$refs.video.srcObject.getTracks().forEach(function (track) {
          track.stop();
        });
      }
    },
    startCamera(){
      navigator.mediaDevices.getUserMedia({audio:false,video: {
          facingMode:'user'
        }})
          .then(mediaStream => {
            this.$refs.video.srcObject = mediaStream;
            //this.$refs.video.setAttribute("muted","")
            this.$refs.video.play()
            this.mediaStream = mediaStream
          })
    },

    retake(){
      this.imageData.image=''
      this.startCamera();
    },
    upload(){
      this.$emit("photo",this.imageData.image);
    },
    captureImage() {
      if('ImageCapture' in window){

      const mediaStreamTrack = this.mediaStream.getVideoTracks()[0]
      const imageCapture = new window.ImageCapture(mediaStreamTrack)
      let reader = new FileReader();
      return imageCapture.takePhoto().then(blob => {
        reader.readAsDataURL(blob)
        reader.onload = () => {
          this.imageData.image = reader.result;
          this.stopCamera();
        }
      })
      }else{
        let video=this.$refs.video;
        const canvas = this.$refs.canvas;
        const { videoWidth, videoHeight } = video;
        canvas.width=videoWidth;
        canvas.height=videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(video,0,0,videoWidth,videoHeight);
        const data = canvas.toDataURL("image/png");
        this.imageData.image=data;

      }
    },
  }
}
</script>

<style scoped>
.video-mask{
  width: 350px;
  border-radius: 10px;
  overflow: hidden;
}
</style>