<template>
<v-layout wrap justify-center fill-height>
<v-list width="100%" >
  <v-list-item v-for="user of $store.state.app.allUsers" :key="user.id">
    <v-list-item-content>
      <v-list-item-title v-html="user.firstName+' '+user.lastName">

      </v-list-item-title>
      <v-list-item-subtitle v-html="user.email">

      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon>
      <v-menu
          bottom
          left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item link

          >
            <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
            <v-list-item-title>Editieren</v-list-item-title>

          </v-list-item>
          <ResetPassword :email="user.email"/>
        </v-list>
      </v-menu>
    </v-list-item-icon>


  </v-list-item>
</v-list>
  <div style="position: absolute;bottom:15px;right:15px">
    <AdminUserAddNew :open="open"/>
  </div>
</v-layout>
</template>

<script>
import AdminUserAddNew from "@/views/Admin/AdminUserAddNew";
import ResetPassword from "@/views/User/ResetPassword";
export default {
  name: "AdminUser",
  components: {ResetPassword, AdminUserAddNew},
  data(){
    return{
      open:false,
    }
  },

  methods:{
    openUser(news){
      this.open=false;
      this.open=true;
      this.$router.push('/admin/user/'+news.id)
    },
  },
}
</script>

<style scoped>

</style>