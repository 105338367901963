<template>
<v-dialog fullscreen v-model="showC">
  <v-card>
    <v-app-bar flat>
      <v-btn icon @click="close"><v-icon>mdi-arrow-left</v-icon></v-btn>
      <v-app-bar-title v-html="room.name"></v-app-bar-title>
    </v-app-bar>
    <v-sheet color="grey" class="pa-5 mt-5">
      <v-layout justify-center wrap>
        <v-flex xs12 class="d-flex justify-center">
    <v-avatar class="mb-5" size="150px" >
      <v-img :src="imageReactive"
      ></v-img>
      <input v-if="!room.email" type="file"
             class="file-input"
             style="opacity:0;width:100%;height:100%;position: absolute;top:0;left:0"
             @change="updateImage"
             accept="image/png, image/jpeg,image/bmp"
      />
    </v-avatar>
        </v-flex>
        <v-flex xs12 class="d-flex justify-center">
          <div class="mx-auto text--disabled"><span v-if="isGroup">Gruppe</span><span v-else v-html="room.firstName+' '+room.lastName"></span><span v-if="isGroup"><span class="mx-1">-</span><span v-html="room.participantsIds.length"></span> <span>Mitglieder</span></span></div>

        </v-flex>
      </v-layout>
    </v-sheet>
    <v-sheet v-if="isGroup" color="grey" class="pa-5 my-5">
      <p class="text--disabled"><span v-html="room.participantsIds.length"></span> <span>Mitglieder</span></p>
      <v-list  color="transparent">
        <v-menu :disabled="!isChatAdmin" offset-y v-for="participant of participants" :key="participant.id">
          <template v-slot:activator="{on,attrs}">
        <v-list-item v-on="on" v-bind="attrs">
          <v-list-item-avatar>
            <UserAvatar :user="participant"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="participant.firstName+' '+participant.lastName"></v-list-item-title>
            <v-list-item-subtitle v-html="participant.email"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon>
            <v-progress-circular indeterminate v-if="participant.loading"></v-progress-circular>
          </v-list-item-icon>
          <v-list-item-action-text v-if="participant.isAdmin">
            <v-chip small color="accent">Administrator</v-chip>
          </v-list-item-action-text>
        </v-list-item>
          </template>
          <v-list>
            <v-list-item link v-if="!participant.isAdmin" @click="promote(participant)">
              <v-list-item-title >Zum Administrator ernennen</v-list-item-title>
              </v-list-item>
            <v-list-item link v-else @click="demote(participant)">
              <v-list-item-title >Zum normalen Mitglied herunterstufen</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!participant.isAdmin" link>
              <v-list-item-title  @click="kick(participant)">aus Gruppe entfernen</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list>
    </v-sheet>
    <v-btn v-if="isGroup" @click="leave(room.id)" block color="red accent-1" dark><v-icon>mdi-logout</v-icon> Gruppe verlassen</v-btn>
    <v-sheet  v-else color="grey" class="pa-5 my-5">
      <p class="text-center"><b>Email</b> <span v-html="room.email"></span></p>
    </v-sheet>
  </v-card>
  <ImageCropper :has-image="img!=null" :img="img" @updateImage="uploadImage"/>
</v-dialog>
</template>

<script>
import ImageCropper from "@/views/ImageCropper";
import {leaveGroupChatRoom, sendGroupMessage, updateGroupChatRoom} from "@/services/chat.service";
import ApiService from "@/services/api.service";
import UserAvatar from "@/views/User/UserAvatar";
import {NotifiyClient} from "@/models/notification";
import UploadFile from "@/services/upload-file";
import {deleteFile} from "@/services/file.service";
export default {
  name: "GroupInfo",
  components: {UserAvatar,ImageCropper},
  props:{
    room:Object,
    show:Boolean
  },
  watch:{
    show(){
      if(this.isGroup) this.findParticipants();
    }
  },
  computed:{
    showC:{
      get(){
        return this.show;
      },set(value){
        this.$emit("update",value);
      }
    },
    imageReactive(){
      if(this.room) {
        if(this.room.profilePic){
          return this.room.profilePic ? 'data:image/png;base64,' + this.room.profilePic.file : 'https://ui-avatars.com/api/?name=' + this.room.name;

        }
        else return this.room.image ? 'data:image/png;base64,' + this.room.image.file : 'https://ui-avatars.com/api/?name=' + this.room.name;
      }
      return null;
    },
    isChatAdmin(){
      return this.$store.state.chat.activeContact.roomAdmin.includes(this.$store.state.auth.user.id)
    },
    isGroup(){
      return this.room.email==null || this.room.email=='';
    }
  },
  data(){
    return{
      participants:[],
      img:null,
      hasImage:false
    }
  },
  created(){
    if(this.isGroup) this.findParticipants();

  },
  methods:{
    uploadImage(image){
      UploadFile(image).then(response => {
        let fileToDelete=null;
        if( this.$store.state.chat.activeContact.image && this.$store.state.chat.activeContact.image.id){
          fileToDelete = this.$store.state.chat.activeContact.image;
        }
        this.$store.state.chat.activeContact.image=response.data;
        let self=this;
        updateGroupChatRoom(this.$store.state.chat.activeContact).then(room =>{
          self.$store.state.chat.activeContact=room;
          if(fileToDelete) deleteFile(fileToDelete);
        })
        sendGroupMessage(this.$store.state.chat.activeContact.id," das Gruppenbild aktualisiert.",null,null,"3")


      });

      this.$store.dispatch("loading/success");
      this.img=null;
    },
    updateImage: function (e) {
      this.hasImage = true
      let files = e.target.files || e.dataTransfer.files;
      if(!files.length) return;
      else this.img = URL.createObjectURL(files[0]);

    },
    kick(participant){
      let self=this;
      ApiService.post("chatroom/participants/remove",{groupId:this.room.id,userId:participant.id}).then(response => {
        self.$store.state.chat.activeContact=response.data;
        self.findParticipants(null);
        self.$store.dispatch("notificationsStore/add",new NotifiyClient("success","Erfolreich entfernt"))
      });
    },
    leave(roomId){
      leaveGroupChatRoom(roomId).then(roomOrUser => {
        if(roomOrUser) {
          this.$emit('close');
          this.$router.push("/chat");
        }
      });

    },
    promote(user){
      user.loading=true;
      ApiService.post("chatroom/participants/promote",{groupId:this.room.id,userId:user.id}).then(response => {
        this.$store.state.chat.activeContact=response.data;
        this.findParticipants(user);
      })
    },demote(user){
      ApiService.post("chatroom/participants/demote",{groupId:this.room.id,userId:user.id}).then(response => {
        this.$store.state.chat.activeContact=response.data;
        this.findParticipants(user);
      })
    },
    close(){
      this.$emit('close');
    },
    findParticipants(userP){
      let self=this;
      ApiService.post("user/getAllByIds",{ids:this.room.participantsIds}).then(response => {
        for(let user of response.data){
          user.isAdmin= self.room.roomAdmin.includes(user.id);
        }
        self.participants=response.data;
        if(userP) userP.loading=false;
      })
    }
  }
}
</script>

<style scoped>

</style>