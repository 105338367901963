<template>
  <v-sheet  color="transparent" :width="width" :height="height">
  <div @click="overlay=true"><!--<pdf v-if="url" :src="url"></pdf>--></div>
    <v-overlay
        v-if="!readonly"
        @click="overlay = false"
        :value="overlay"
    >
      <pdf v-if="url" :src="url"></pdf>
      <v-btn block @click="downloadFile"><v-icon>mdi-download</v-icon> Download</v-btn>
    </v-overlay>
  </v-sheet>
</template>

<script>
//import pdf from 'vue-pdf'
export default {
  data(){
    return{
    overlay:false
    }
  },
  components:{
  },
  props:{
    readonly:Boolean,
    name:String,
    url:[String,Uint8Array],
    width:String,
    height:String
  },
  name: "PDFViewer",
  methods:{
    downloadFile(){
      var a = document.createElement("a"); //Create <a>
      a.href = this.url; //Image Base64 Goes here
      a.download = this.name; //File name Here
      a.click(); //Downloaded file
    }
  }
}
</script>

<style scoped>

</style>