import ApiService from "@/services/api.service";
import store from "@/store/index";
import {NotifiyClient} from "@/models/notification";

export const news = {
    namespaced: true,
    state: {
        all: [],
    },
    actions:{
        save({commit},news){
          return ApiService.post("news/create",news).then(response => {
              commit('saveSuccess',response.data);
              store.dispatch("loading/success");

          },error =>{
              console.error(error);
              store.dispatch("notificationsStore/add",new NotifiyClient("warning","Fehler",false,"Beitrag konnte nicht gespeichert werden."))
              store.dispatch("loading/error");
          })
        },
        updateSpecific({commit},newsItem){
            commit('updateSpecific',newsItem);
        },
        getAllNews({commit}){
            return ApiService.get("news/all").then(
                response => {
                    commit('getAllNewsSuccess',response.data);
                },
                error =>{
                    console.error(error);
                    store.dispatch("notificationsStore/add",new NotifiyClient("warning","Fehler",false,"News konnten nicht geladen werden."))

                });
        },
    },
    mutations: {
        saveSuccess(state,data){
            const index = state.all.findIndex(item => item.id === data.id);
            if(index!=-1){
                state.all[index]=data;
            }
            else state.all.push(data);
        },

        getAllNewsSuccess(state,data){
            state.all=data;
        },
        updateSpecific(state,data){
            let index= state.all.findIndex(item => item.id === data.id);
            if(index !=-1) state.all[index]=data;
        },

    }


}