<template>
  <div  class="mx-2 d-flex justify-space-between align-center" style="width:100%">
    <v-text-field
        style="width: 60%"
        label="Nach Mitarbeitern suchen"
        placeholder="Suchen..."
        v-model="$store.state.navigation.payrollTabs.searchQuery"
        hide-details
        append-icon="mdi-magnify"
    outlined dense></v-text-field>

  <v-tabs
      class="ml-10"
      grow
      v-model="$store.state.navigation.payrollTabs.selected"
      background-color="transparent"
  >
    <v-tab
        v-for="item in $store.state.navigation.payrollTabs.items"
        :key="item"
    >
      <div v-if="item==='anfragen' && $store.state.payroll.requests.length>0">
        <v-badge bordered :content="$store.state.payroll.requests.length">
        <span>{{ item }}</span>
        </v-badge>
      </div>
      <span v-else>{{item}}</span>
    </v-tab>
  </v-tabs>
  </div>
</template>

<script>
export default {
  name: "PayrollTopBar",
}
</script>

<style scoped>

</style>