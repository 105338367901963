<template>
  <v-dialog
    v-model="show"
  persistent>
    <template v-slot:activator="{on,attrs}">
      <v-list-item two-line link v-on="on" v-bind="attrs">
        <v-list-item-avatar>
          <Avatar :name="user.firstName+user.lastName" :file="user.profilePic"/>
        </v-list-item-avatar>
        <v-list-item-title>
          <span v-html="user.firstName+' '+user.lastName"></span>
        </v-list-item-title>
        <v-list-item-subtitle v-if="lastPayroll">
          <span>Letzte: </span><span v-html="betterLastPayroll"></span>
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          <span>-</span>
        </v-list-item-subtitle>
        <v-list-item-action-text>
          <v-btn small text @click="openChat(user.id)"><v-icon>mdi-message</v-icon> chatten </v-btn>
        </v-list-item-action-text>
      </v-list-item>
    </template>
    <v-card>
      <v-app-bar flat>
        <v-app-bar-title class="px-5">Gehaltsabrechungen hochladen.</v-app-bar-title>
        <v-spacer/>
        <div class="caption px-5"><v-chip><v-icon small>mdi-shield-lock</v-icon></v-chip> = <span>steht dem Mitarbeiter bereit</span></div>
        <div class="caption px-5"><v-chip><v-icon small>mdi-eye</v-icon></v-chip> = <span>Mitarbeiter hat geöffnet</span></div>
      </v-app-bar>

    <v-sheet class="my-5 px-5 py-2" >

      <v-layout wrap align-center justify-start>
        <v-flex xs12 md4 class="px-5">
          <v-sheet height="125px" class="d-flex align-center" elevation="1" color="grey">
          <v-list-item>
            <v-list-item-avatar size="75px">
              <Avatar :name="user.firstName+user.lastName" :file="user.profilePic"/>
            </v-list-item-avatar>
            <v-list-item-content>
            <v-list-item-title>
              <b style="font-size: 1.5rem;" v-html="user.firstName+' '+user.lastName"></b>
            </v-list-item-title>
              <v-list-item-subtitle v-html="user.email">

              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="success">mdi-lock-open</v-icon>
            </v-list-item-icon>
          </v-list-item>
          </v-sheet>
        </v-flex>
        <v-flex shrink>
          <v-sheet color="grey" v-ripple style="width:200px;height:125px;position: relative" class="d-flex flex-wrap justify-center pa-5 my-5 mr-5"><v-icon large>mdi-plus</v-icon><div style="pointer-events: none" class="text-center text--disabled" ><span v-if="uploadPipe.length>0">Weitere Dateien hinzufügen</span><span v-else>Dateien hochladen</span></div>
            <input type="file"
                   accept="application/pdf,application/vnd.ms-excel"
                   multiple
                   class="file-input"
                   style="opacity:0;width:100%;height:100%;position: absolute;top:0;left:0"
                   @change="uploadFiles"
            />
          </v-sheet>
        </v-flex>
        <v-flex style="height: 125px" grow md5 class="my-5 d-flex flex-wrap justify-start">
          <UploadPreviewPayRoll  v-for="(fileObject,$index) of currentAndUploadPipe" @remove="removeFileFromUploadPipe" :key="$index" :fileObject="fileObject" :readonly="fileObject.readonly" :index="$index"/>
        </v-flex>
      </v-layout>
    </v-sheet>
      <v-card-actions>
        <v-spacer/>
        <v-btn :disabled="$store.state.loading.loadingState" @click="show=false" text>Schließen</v-btn>
        <v-btn :loading="$store.state.loading.loadingState" color="success" @click="saveBulk" :disabled="uploadPipe.length<=0">Verschlüsselt hochladen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {toBase64} from "@/services/file.service";
import Avatar from "@/views/Avatar";
import UploadPreviewPayRoll from "@/views/Payrolls/UploadPreviewPayRoll";
import {NotifiyClient} from "@/models/notification";
import ApiService from '@/services/api.service';
//TODO Liste von Users -> Upload Files with uploadPipe -> YearMonth in Menu
// Wenn YearMonth noch fehelnd -> Rot , sonst grün
export default {
  name: "UploadPayroll",
  components: {UploadPreviewPayRoll, Avatar},
  props:{
    user:Object
  },
  data(){
    return{
      uploadPipe:[],
      currentItems:[],
      picker:null,
      userId:"",
      show:false,
      lastPayroll:null
    }
  },
  computed:{
    betterLastPayroll(){
      let moment = require('moment');
      return moment(this.lastPayroll).format("MMM YY");
    },
    currentAndUploadPipe(){
      return this.uploadPipe.concat(this.currentItems).sort(function(a,b) {
        if(a.yearAndMonth && b.yearAndMonth) {
          a = a.yearAndMonth.split("-");
          b = b.yearAndMonth.split("-");
          return new Date(a[0], a[1], 1) - new Date(b[0], b[1], 1)
        }
      });
    }
  },
  created() {
    this.getCurrentPayrolls();
    this.getLastPayroll();
  },
  methods:{
    openChat(id){
  this.$router.push("/chat/user/"+id);
},
    getLastPayroll(){
      let self=this;
      ApiService.get("payroll/get/user/"+this.user.id+"/last").then(response => {
        self.lastPayroll=response.data;
      })
    },
    getCurrentPayrolls(){
      let self=this;
      ApiService.get("payroll/get/user/"+this.user.id).then(response => {
        self.currentItems=response.data;
      })
    },
    removeFileFromUploadPipe(request){
      // console.log(request)
      this.uploadPipe.splice(request.index,1);
      // deleteFile(request.file);
    },
    async uploadFiles(e){
      let files = e.target.files || e.dataTransfer.files;

      await this.createPayrollObjects(files);
    },
    saveBulk(){
      let self=this;
      this.$store.dispatch("loading/toggle",true);
      for(let payroll of this.uploadPipe){
        if(payroll.yearAndMonth==null || payroll.yearAndMonth ===''){
          this.$store.dispatch("notificationsStore/add",new NotifiyClient("error","Monat nicht ausgewählt",false,"Bitte wählen Sie für jede Gehaltsabrechung den zugehörigen Monat"))
          this.$store.dispatch("loading/error");
          return false;

        }
      }
      this.$store.dispatch("payroll/bulkSave",{list:this.uploadPipe}).then(()=>{
        self.uploadPipe=[];
        self.getCurrentPayrolls();

      });
    },
    async createPayrollObjects(files){

      for(let file of files) {
        let base64= await toBase64(file);
        base64=base64.replace("data:","");
        base64=base64.split(";")[1];
        base64=base64.split(",")[1];
        this.uploadPipe.push({id: null, file: {id: null, filename: file.name,contentType:file.type,file:base64,size:file.size,uploadVonId:this.$store.state.auth.user.id,created_at:new Date()}, yearAndMonth: null, userId: this.user.id})
      }
    },
  }
}
</script>

<style scoped>

</style>
