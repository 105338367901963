<template>
<v-layout wrap fill-height style="position:relative">
<v-flex xs12>
  <router-view></router-view>

</v-flex>


</v-layout>
</template>

<script>

export default {
  name: "GroupChat",
  data(){
    return{
      chats:[]
    }
  },
  created() {
    this.$store.dispatch("chat/loadGroupChats");
  },


}
</script>

<style scoped>

</style>