<template>
  <v-layout wrap justify-center fill-height>
<v-list>
  <v-list-item v-for="news of $store.state.news.all" :key="news.id">
    <v-list-item-title v-html="news.title"></v-list-item-title>
    <v-list-item-action>
      <v-btn icon @click="openNews(news)"><v-icon>mdi-pen</v-icon></v-btn>
    </v-list-item-action>
  </v-list-item>

</v-list>
    <div style="position:absolute;bottom:15px;right:15px;">
      <AdminNewsAddNew :open="open" :id="id"/>
    </div>
  </v-layout>
</template>

<script>
import AdminNewsAddNew from "@/views/Admin/AdminNewsAddNew";
export default {
  name: "AdminNews",
  components: {AdminNewsAddNew},
  data(){
    return{
      open:false,
    }
  },

  methods:{
  openNews(news){
    this.open=false;
    this.open=true;
    this.$router.push('/admin/news/'+news.id)
  },
  },props:{
    id:Number
  }
}
</script>

<style scoped>

</style>