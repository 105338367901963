import moment from "moment";
import ApiService from "@/services/api.service";


const initialState = {
    termine:[],

    value:"",
    showDialog:false,
    terminToAdd:{},
    ref:null
}

export const calendar = {
    namespaced: true,
    state: initialState,
    actions: {
        init({commit},termine){
          commit("init",termine)
        },
        toggle({commit},status){
          commit("toggle", status)
        },

        save({ commit }, termine) {
            commit("save",termine)
        },
        add({ commit }, termin) {
            commit("add",termin)
        },
        edit({ commit }, termin) {
            commit("edit",termin)
        },
        getAllEvents({commit}){
            return ApiService.get("calendar/termin/list-mine").then(response => {
                commit("init",response.data);
                return Promise.resolve(response.data);
            })
        }
        },

    mutations: {
        save(state, termine) {
            state.immobilien = termine;
        },
        add(state, termin){
            let index=state.termine.findIndex(x=>x.id===termin.id)

            termin.start = moment(termin.start).format("YYYY-MM-DD HH:mm")
            termin.end = moment(termin.end).format("YYYY-MM-DD HH:mm")
            if(index != -1){
                state.termine[index] = termin;
            }else {

                state.termine.push(termin);
            }
            },

        toggle(state, status){
            state.showDialog=status;

        },
        edit(state, termin){
            state.terminToAdd=termin;
        },
        init(state,termine){
            for(let event of termine){
                event.start=moment(event.start).format("YYYY-MM-DD HH:mm");
                event.end=moment(event.end).format("YYYY-MM-DD HH:mm");
            }
            state.termine=termine;
        }


    }
};