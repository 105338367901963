import ApiService from "@/services/api.service";
import store from "@/store/index";
import {NotifiyClient} from "@/models/notification";

export const app = {
    namespaced: true,
    state: {
        allUsers:[],
        birthDayUsers:[],
        onlineUsers:[],
        welcome:{
            page:1,
            pages:4,
            progress: 1,
        },
        leftDrawer:true,
        tabs:{
            tabItems:[
                "Laufend",
                "Abgeschlossen"
            ],
            selected:null
        },

        navItems:[
            { title: 'Dashboard', icon: 'mdi-home-outline',link:'/' },
            { title: 'Kalender', icon: 'mdi-calendar' ,link:'/kalender'},
            { title: 'Mitarbeiter', icon: 'mdi-account-group-outline',link:'/mitglieder' },
            { title: 'Nachrichten', icon: 'mdi-message-outline',link:'/chat' },
            { title: 'Aktuelles', icon: 'mdi-information-outline',link:'/aktuelles' },
            { title: 'Formulare', icon: 'mdi-folder-outline',link:'/formulare' },
],
        initialHistory:0
    },
    actions:{

        getAllUsers({commit}){
            return ApiService.get("user/getAll").then(
                response => {
                    commit('getAllUsersSuccess',response.data);
                },
                error =>{
                    console.error(error);
                    store.dispatch("notificationsStore/add",new NotifiyClient("warning","Fehler",false,"Es konnten nicht alle User geladen werden."))
                });
        },
        getAllUsersOnline({commit}){
            return ApiService.get("user/list/here").then(
                response => {
                    commit('getAllUsersOnlineSuccess',response.data);
                },
                error =>{
                    console.error(error);
                    store.dispatch("notificationsStore/add",new NotifiyClient("warning","Fehler",false,"Es konnten nicht alle angemeldeten User geladen werden."))
                });
        },
        getAllUsersBirthday({commit}){
            return ApiService.get("user/list/birthday/soon").then(
                response => {
                    commit('getAllUsersBirthdaySuccess',response.data);
                },
                error =>{
                    console.error(error);
                    store.dispatch("notificationsStore/add",new NotifiyClient("warning","Fehler",false,"Es konnten nicht alle Geburtstage geladen werden."))
                });
        },


    },
    mutations: {


        getAllUsersSuccess(state,data){
            state.allUsers=data;
        },
        getAllUsersOnlineSuccess(state,data){
            state.onlineUsers=data;
        },
        getAllUsersBirthdaySuccess(state,data){
            state.birthDayUsers=data;
        },
    }


}