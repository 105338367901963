<template>
  <v-select multiple outlined label="Rolle" :items="roles" item-text="display" item-value="name" v-model="userC.role" placeholder="Rolle"></v-select>
</template>

<script>
import ApiService from "@/services/api.service"
export default {
  name: "SelectUserRole",
  props:{
    user:Object
  },
  computed:{
    userC:{
      get(){
        return this.user;
      },set(value){
        //TODO emit
        this.$emit("update",value);
      }
    }
  },
  data(){
    return{
      roles:[]
    }
  },
  created() {
    this.getRoles();
  },
  methods:{
    getRoles(){
      let self=this;
      ApiService.get("role/list/all").then(response => {
        self.roles=response.data;
      })
    }
  }
}
</script>

<style scoped>

</style>