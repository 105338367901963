<template>
  <v-navigation-drawer
      color="grey"
      app
      right
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
  >
    <v-btn
        style="position:absolute;top:0;left:0;"
        icon
        @click.stop="mini = !mini"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
    <v-menu v-if="currentUser" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
    <v-list-item v-bind="attrs" v-on="on" class="px-2" :class="{'ml-10' : !mini}">
      <v-list-item-avatar>
        <Avatar :file="currentUser.profilePic" :name="currentUser.firstName+' '+currentUser.lastName"/>
      </v-list-item-avatar>

      <v-list-item-title v-html="currentUser.firstName+' '+currentUser.lastName"></v-list-item-title>


        <v-btn icon>
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
    </v-list-item>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title><span v-if="currentUser.here">Online</span><span v-else>Offline</span></v-list-item-title><v-list-item-action><v-switch :loading="$store.state.loading.loadingState" v-model="currentUser.here" :color="currentUser.here ? 'green' : 'red'" @change="$store.dispatch('auth/toggleStatus')"></v-switch></v-list-item-action>
          </v-list-item>
        <v-list-item
            link
            :href="item.link"
            v-for="(item, index) in profileMenue"
            :key="index"
        >
          <v-list-item-title><v-icon small v-html="item.icon"></v-icon> {{ item.title }}</v-list-item-title>
        </v-list-item>
        </v-list>
      </v-menu>
    <v-list-item v-else class="px-2" link @click="$router.push('/login')" :class="{'ml-10' : !mini}">
      <v-list-item-avatar>

      </v-list-item-avatar>

      <v-list-item-title>Login</v-list-item-title>

    </v-list-item>

    <v-divider></v-divider>
<v-spacer style="height:50px"/>
    <v-list :class="{'px-5': !mini}" dense>
      <v-list-item>
        <v-list-item-icon v-if="mini">
          <v-icon>mdi-gift</v-icon>
        </v-list-item-icon>
        <v-list-item-title><h4>Kommende Geburtstage</h4></v-list-item-title>
      </v-list-item>

      <UserBirthday v-for="user of $store.state.app.birthDayUsers" :key="user.id" :user="user"></UserBirthday>

    </v-list>
    <v-list :class="{'px-5': !mini}" dense>
      <v-list-item link @click="$router.push('/chat')">
        <v-list-item-icon v-if="mini">
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-title><h4>Deine Gruppen</h4></v-list-item-title>
      </v-list-item>
      <ChatButtonUniversal small v-for="group of $store.state.chat.groupChats.slice(0,5)" :key="group.id" :chat="group">

      </ChatButtonUniversal>
    </v-list>

    <v-list :class="{'px-5': !mini}" dense>
      <v-list-item>
        <v-list-item-icon v-if="mini">
          <v-icon color="green">mdi-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-title><h4>Wer ist da?</h4></v-list-item-title>

      </v-list-item>
      <div style="margin-left: 20px" class="px-2">
        <v-hover
            v-slot="{ hover }"
            v-for="user of $store.state.app.onlineUsers" :key="user.id"
        >
          <v-avatar size="45px" style="margin-left: -20px" :class="{'front' : hover}">
            <Avatar :file="user.profilePic" :name="user.firstName+'+'+user.lastName"/>

          </v-avatar>
        </v-hover>
      </div>
    </v-list>

  </v-navigation-drawer>
</template>

<script>
import UserBirthday from "@/views/User/UserBirthday";
import ChatButtonUniversal from "@/views/Chat/ChatButtonUniversal";
import Avatar from "@/views/Avatar";

export default {
  name: "RightDrawer",
  components: {Avatar, ChatButtonUniversal, UserBirthday},
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
  data(){
    return{
      drawer: true,
          items: [
        { title: 'Home', icon: 'mdi-home-city' },
        { title: 'My Account', icon: 'mdi-account' },
        { title: 'Users', icon: 'mdi-account-group-outline' },
      ],
      profileMenue:[
        { title: 'Profil', icon: 'mdi-user-edit',link:'/profile' },
        { title: 'ausloggen', icon: 'mdi-logout',link:'/logout'},
      ],
          mini: false,
    }
  }
}
</script>

<style scoped>
.front{
  z-index:10
}
</style>