<template>
  <v-navigation-drawer
      app
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
  >
    <v-list-item class="pb-5 px-2">
      <div class="d-flex justify-center pa-2" style="width:100%">
      <v-img width="30px" style="max-width: 100%; background-color: grey" :src="require('@/assets/3655_9_1_m.png')"></v-img>
      </div>
      <v-spacer/>

    </v-list-item>
    <v-btn
        style="position:absolute;top:0;right:0;"
        icon
        v-if="!mini"
        @click.stop="mini = !mini"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>


    <v-list dense>
      <v-list-item
          v-for="item in $store.state.app.navItems"
          :key="item.title"
          link
          :href="item.link"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon small>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item
          v-if="$store.state.auth.user.roles.includes('ROLE_ADMIN')"
          link
          href="/admin"
      >
        <v-list-item-icon>
          <v-icon>mdi-lock-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Administrator</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon small>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item><v-list-item two-line
          v-if="$store.state.auth.user.roles.includes('ROLE_PERSONALER')"
          link
          href="/gehaltsabrechnungen"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-cash-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Gehaltsabrechnungen</v-list-item-title>
          <v-list-item-subtitle>
            WYP (Adminansicht)
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon small>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "LeftDrawer",
  data(){
    return{
      drawer: true,
      items: [
        { title: 'Dashboard', icon: 'mdi-home-outline',link:'/' },
        { title: 'Kalender', icon: 'mdi-calendar' ,link:'/kalender'},
        { title: 'Mitglieder', icon: 'mdi-account-group-outline',link:'/mitglieder' },
        { title: 'Nachrichten', icon: 'mdi-message-outline',link:'/chat' },
        { title: 'Aktuelles', icon: 'mdi-information-outline' },
        { title: 'Formulare', icon: 'mdi-folder-outline' },
      ],
      mini: false,
    }
  }
}
</script>

<style scoped>

</style>