<template>
<div v-if="item" style="position:relative;">
  <v-layout class="py-2">
    <v-flex xs9 class="d-flex align-center">
      Fragen oder andere Anliegen? Chatten Sie hier direkt mit Ihrem Ansprechpartner:
    </v-flex>
    <v-flex>
      <ChatButtonUniversal small :chat="ansprechpartner" :name="ansprechpartner.firstName+' '+ansprechpartner.lastName"/>
    </v-flex>
  </v-layout>
  <v-layout>
    <v-flex grow>
      <v-sheet elevation="1">
        <v-system-bar>
          <v-icon>mdi-lock</v-icon>
          <v-spacer/>
          <span class="" v-html="betterYearAndMonth"></span>

          <v-spacer></v-spacer> <small class="">Zuletzt geöffnet: <span v-html="lastOpened"></span></small>
        </v-system-bar>
        <PDFViewer readonly :name="item.file.filename" :url="'data:'+item.file.contentType+';base64,'+item.file.file" width="100%"> </PDFViewer>
      </v-sheet>
    </v-flex>
  </v-layout>
<v-tooltip left>
  <template v-slot:activator="{attrs,on}">
    <v-btn @click="downloadFile" v-on="on" v-bind="attrs" color="accent" fab style="position: fixed" :style="{ 'right': position.right + 'px', 'bottom': position.bottom + 'px' }">
      <v-icon>mdi-download</v-icon>
    </v-btn>
  </template>
  Sicher herunterladen
</v-tooltip>

</div>
</template>

<script>
import PDFViewer from "@/views/PDFViewer";
import ApiService from "@/services/api.service"
import ChatButtonUniversal from "@/views/Chat/ChatButtonUniversal";
export default {
  name: "PayrollSite",
  components:{ChatButtonUniversal, PDFViewer},
  props:{
    id:String
    },
  data(){
    return{
      item:null,
      ansprechpartner:null,
    }
  },
  computed: {
    betterYearAndMonth() {
      let moment = require('moment');
      return moment(this.item.yearAndMonth).format("MMM YYYY");
    },
    lastOpened(){
      let moment= require('moment');
      return moment(this.item.lastOpened).calendar();
    },
    position(){
      if(this.$vuetify.breakpoint.mdAndUp){
        return { right:270,bottom:15}
      }else{
        return { right:15,bottom:75}
      }

    },
  },
  created() {
   this.getItem();
  },
  methods:{
    downloadFile(){
      var a = document.createElement("a"); //Create <a>
      a.href = 'data:'+this.item.file.contentType+';base64,'+this.item.file.file; //Image Base64 Goes here
      a.download = this.item.file.filename; //File name Here
      a.click(); //Downloaded file
    },
    getAnsprechpartner(){
      let self=this;
      ApiService.get("user/get?id="+this.item.file.uploadVonId).then(response => {
        response.data.name=response.data.firstName+' '+response.data.lastName;
        self.ansprechpartner=response.data;
      })
    },
    getItem(){
      let self=this;
      ApiService.post("payroll/get",{string:this.id}).then(response => {
        self.item=response.data;
        self.getAnsprechpartner();
      })
    }
  }
}
</script>

<style scoped>

</style>