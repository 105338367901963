<template>
<v-layout wrap>
  <v-flex xs12 md6 v-for="user of $store.state.app.allUsers" :key="user.id">
    <div class="px-2 py-2">
    <UserItem :user="user"/>
    </div>
  </v-flex>

</v-layout>
</template>

<script>
import UserItem from "@/views/User/UserItem";
export default {
  name: "UserList",
  components: {UserItem}
}
</script>

<style scoped>

</style>