import ApiService from "@/services/api.service";
import store from "@/store"
import {NotifiyClient} from "@/models/notification";

const initialState = {
    stompClient:null,
    connectionStatus:false,
    contacts:[],
    activeContact:null,
    isGroup:false,
    messages:[],
    currentChatPartners:[],
    groupChats:[],
    userChats:[],
    allChats:[],
    showChats:'all'
}

export const chat = {
    namespaced: true,
    state: initialState,
    actions: {
        loadActiveUser({commit},request){
            if(request.isGroup){
                return ApiService.get("chatroom/get/"+request.id).then(response => {
                    commit("changeActiveContact", response.data);
                    return Promise.resolve(response.data);
                },error => {
                        console.error(error);
                });
            }else {
                return ApiService.get("user/get?id=" + request.id).then(response => {
                    response.data.name=response.data.firstName+' '+response.data.lastName;
                    commit("changeActiveContact", response.data);

                    return Promise.resolve();
                },error => {
                    console.error(error);
                });
            }
        },
        contacts({commit},contacts){
          commit("contacts",contacts)
        },
        createGroupChat({commit},data){
            return ApiService.post("chatroom/create",data).then(response => {
               commit("successCreatedChatRoom",response.data)
            });
        },
        loadGroupChats({commit}){
            return ApiService.get("chatroom/all").then(response => {
                commit("loadGroupChatSuccess",response.data);
            });
        },
        loadUserChats({commit},chatPartners){
            return ApiService.post("user/getChats",{ids:chatPartners}).then(response => {

                commit("loadUserChatSuccess",response.data);
            });
        },
        loadAllChats({commit},chatPartners){
            return ApiService.get("chatroom/all").then(groups => {
                commit("loadGroupChatSuccess",groups.data.sort(function(a,b) {
                    if((b.lastMessage && a.lastMessage) && b.unreadMessages===a.unreadMessages){
                        return b.lastMessage.timestamp-a.lastMessage.timestamp;
                    }else{
                        return b.unreadMessages - a.unreadMessages
                    }}));
                return ApiService.post("user/getChats",{ids:chatPartners}).then(users => {
                    for(let user of users.data){
                        user.name=user.firstName+" "+user.lastName;
                    }
                    commit("loadUserChatSuccess",users.data.sort(function(a,b) {
                        if((b.lastMessage && a.lastMessage) && b.unreadMessages===a.unreadMessages){
                            return b.lastMessage.timestamp-a.lastMessage.timestamp;
                        }else{
                            return b.unreadMessages - a.unreadMessages
                        }}));

                    let result=
                        groups.data.concat(users.data).sort(function(a,b) {
                            if((b.lastMessage && a.lastMessage) && b.unreadMessages===a.unreadMessages){
                                    return b.lastMessage.timestamp-a.lastMessage.timestamp;
                        }else{
                                return b.unreadMessages - a.unreadMessages
                            }});
                    commit("loadAllChatsSuccess",result);
                });

            });
        },
        changeConnectionStatus({commit},status){
            commit("changeConnectionStatus",status)
        },
        changeActiveContact({commit},contact){
            commit("changeActiveContact",contact)
        },

        connect({ commit }, client) {
            commit("connect",client)
        },
        messages({commit},messages){
          commit("messages",messages)
        },
        chatPartners({commit},users){
            commit("chatPartners",users)
        },
        addChatRoomParticipants({commit},request){
            ApiService.post("chatroom/participants/add",request).then(response => {
                commit("addChatRoomParticipantsSuccess",{id:request.groupId,participants:response.data});
            },
            error=>{
               console.error(error);
                store.dispatch("notificationsStore/add",new NotifiyClient("error","Konnte Teilnehmer nicht hinzufügen",false,"Bitte versuchen Sie es erneut."))
            });
        }
    },

    mutations: {
        successCreatedChatRoom(state,chatRoom){
          state.groupChats.push(chatRoom);
          state.allChats.push(chatRoom);
        },
        loadGroupChatSuccess(state,chats){
          state.groupChats=  chats;
        },
        loadUserChatSuccess(state,chats){
            state.userChats=  chats;
            state.allChats=chats;
        },
        loadAllChatsSuccess(state,chats){
            state.allChats=  chats;
        },
        changeConnectionStatus(state, status) {
            state.connectionStatus=status;
        },
        chatPartners(state,users){
            for(let user of users){
               if(!user.newMessages) user.newMessages=0
                if(!user.lastMessage) user.lastMessage=null
            }
            state.currentChatPartners=users;
        },
        connect(state, client) {
            state.stompClient = client;
        },
        contacts(state,contacts){
            state.contacts=contacts;
        },
        changeActiveContact(state,contact){
            state.activeContact=contact;
        },
        messages(state,messages){
            state.messages=messages;
        },
        addChatRoomParticipantsSuccess(state, groupIdAndParticipantsIds){
            let index = state.allChats.findIndex(x => x.id === groupIdAndParticipantsIds.id);
            if(index != -1){

                state.allChats[index].participantsIds = groupIdAndParticipantsIds.participants;
            }
        }

    }
};