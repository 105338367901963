<template>
  <div class="d-flex flex-nowrap align-center">
    <v-btn
        fab
        text
        small
        color="grey darken-2"
        @click="prev"
    >
      <v-icon small>
        mdi-chevron-left
      </v-icon>
    </v-btn>
    <v-btn
        fab
        text
        small
        color="grey darken-2"
        @click="next"
    >
      <v-icon small>
        mdi-chevron-right
      </v-icon>
    </v-btn>
<v-btn icon>
  <v-icon v-if="calendar.type==='day'">mdi-calendar</v-icon>
  <v-icon v-if="calendar.type==='month'">mdi-calendar-month</v-icon>
  <v-icon v-if="calendar.type==='week'">mdi-calendar-week</v-icon>
    <v-select

        light

        v-model="calendar.type"
        :items="[{
          text:'Monat',
          value: 'month'},
          {
          text:'Woche',
          value: 'week'},
          {
          text:'Tag',
          value: 'day'}]"
        dense
        outlined
        hide-details
        class="ma-2 shrink"
        style="width:150px;opacity:0;position:absolute;width:100%;"
    ></v-select>
</v-btn>
    <v-btn :disabled="$store.state.calendar.value===''"
        @click="setToday"
    icon>
      <v-icon>mdi-calendar-today</v-icon>
    </v-btn>

    <span v-if="$store.state.calendar.ref"> {{ $store.state.calendar.ref.title }}</span>

  </div>
</template>

<script>
export default {
  name: "CalendarTopBar",
  computed:{
    calendar:{
      get(){
        return this.$store.state.navigation.calendar;
      },
      set(calendar){
        this.$store.state.navigation.calendar=calendar;
      }
    }
  },
  methods:{
    prev () {
      this.$store.state.calendar.ref.prev()
    },
    next () {
      this.$store.state.calendar.ref.next()
    },
    setToday () {
      this.$store.state.calendar.value = ''
    },
  }
}
</script>

<style scoped>

</style>