<template>
  <v-sheet rounded class="my-2" color="white">
<v-list-item>

<v-list-item-content>
  <v-list-item-title ><span v-if="formItem.title" v-html="formItem.title"></span><span v-else-if="formItem.file" v-html="formItem.file.filename"></span></v-list-item-title>
  <v-list-item-subtitle v-html="formItem.description"></v-list-item-subtitle>
</v-list-item-content>
  <v-list-item-icon><v-btn icon @click="download"><v-icon>mdi-cloud-download-outline</v-icon></v-btn></v-list-item-icon>
</v-list-item>
  </v-sheet>
</template>

<script>
import {downloadBlobFile} from "@/services/file.service";
//TODO Filter
export default {
  name: "FormItem",
  props:{
    formItem:Object
  },
  methods:{
    download(){
      downloadBlobFile(this.formItem.file.file,this.formItem.file.contentType,this.formItem.file.filename)
    },
  }
}
</script>

<style scoped>

</style>