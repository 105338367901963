<template>
  <v-dialog
      v-model="show"
      max-width="300px"
  >
    <v-card>
      <v-card-title>
        <span>Sitzung abgelaufen</span>
      </v-card-title>
      <v-card-text class="p-3">
        <p>Wegen Inaktivität werden Sie in<br/> {{ seconds }} Sekunde<span v-if="seconds>1">n</span> automatisch ausgeloggt.</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      time: 10000,
      show:true,
    }
  },
  computed:{
    seconds(){
      return this.time/1000;
    }
  },
  created() {
    let timerId = setInterval(() => {
      this.time -= 1000;
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
      if (this.time < 1) {
        clearInterval(timerId);
        // Your logout function should be over here
      this.$router.push('/logout');
      }
    }, 1000);
  }
};
</script>