<template>
  <v-layout wrap>
    <v-flex xs12 md8 class="pa-2">
      <v-sheet class="px-10 py-5" color="grey" rounded>
        <KalenderKachel/>
      </v-sheet>
    </v-flex>
    <v-flex xs12 md4 class="pa-2">
      <v-sheet style="height:100%" color="grey" class=" py-5" rounded>
        <div>
          <h3 class="px-10 text-center">Deine neuesten Nachrichten</h3>
          <div v-for="user of $store.state.chat.allChats" :key="user.id">
            <ChatButtonUniversal  :chat="user" ></ChatButtonUniversal>
            <v-divider class="mx-5"></v-divider>
          </div>
        </div>
      </v-sheet>
    </v-flex>
    <v-flex xs12 md4 class="pa-2">
      <v-sheet style="height:100%" min-height="250px" class="px-2 py-5" color="grey" rounded>
        <div>
          <h3 class="text-center">Aktuelles</h3>
          <div v-if="$store.state.news.all.length>0">
            <NewsItem v-for="news of $store.state.news.all" :key="news.id" :news="news"/>
          </div>
          <div class="caption text-center" v-else>News konnten nicht geladen werden.</div>
        </div>
      </v-sheet>
    </v-flex>
    <v-flex xs12 md4 class="pa-2">
      <v-sheet style="height:100%" class="px-2 py-5" color="grey" rounded>
        <div>
          <h3 class="text-center">Formulare</h3>

          <FormItem v-for="form of $store.state.forms.all.slice(0,5)" :key="form.id" :form-item="form"></FormItem>
                  </div>
      </v-sheet>
    </v-flex>
    <v-flex xs12 md4 class="pa-2">
      <v-sheet style="height:100%" class="px-1 py-5" color="grey" rounded>
        <PayrollHomeSheet/>
      </v-sheet>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiService from '@/services/api.service';
import KalenderKachel from "@/views/Dashboard/KalenderKachel";
import NewsItem from "@/views/News/NewsItem";
import FormItem from "@/views/Forms/FormItem";
import ChatButtonUniversal from "@/views/Chat/ChatButtonUniversal";
import PayrollHomeSheet from "@/views/PayrollHomeSheet";

export default {
  name: "HomeView",
  components: {PayrollHomeSheet, ChatButtonUniversal, FormItem, NewsItem, KalenderKachel},
  data(){
    return{
    newestMessages:[],
      result:[],
      payrollsSecurity:true
    }
  },
  created(){
    let self=this;
    let result= [];
    ApiService.get("user/messages/newest/5").then(function(response){
      self.newestMessages=response.data
      for(let message of response.data){
        ApiService.get("user/get?id="+message.senderId).then(response2 => {
          response2.data.lastMessage=message;
          result.push(response2.data);
        });
      }
      self.result=result;
    });
  }
}
</script>

