<template>
    <v-list-item @click="reset" link color="warning"
    >
      <v-list-item-icon><v-icon>mdi-key</v-icon></v-list-item-icon>
      <v-list-item-title>Passwort zurücksetzen</v-list-item-title>

    </v-list-item>
</template>

<script>
import ApiService from '@/services/api.service'
import {NotifiyClient} from "@/models/notification";
export default {
  name: "ResetPassword",
  props:{
    email:String
  },
  methods:{
    reset(){
      let self=this;
      if (confirm("Willst du das Password dieses Mitarbeiters wirklich zurücksetzen?") == true) {

        ApiService.post("user/password/reset",{string:this.email}).then((response) => {
          if(response) {
            self.$store.dispatch("notificationsStore/add", new NotifiyClient("success", "Passwort geändert", false, "Mitarbeiter wurde neues Passwort an " + self.email + " gesendet."));
          }
          });
      }
    }
  }
}
</script>

<style scoped>

</style>