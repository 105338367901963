<template>
  <v-card>
    <v-toolbar>
      <v-btn icon @click="stepBack"><v-icon>mdi-arrow-left</v-icon></v-btn>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Neue Gruppe</v-list-item-title>
          <v-list-item-subtitle>Betreff hinzufügen</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-toolbar>
    <v-list color="grey" class="my-5">
      <v-list-item>
        <v-list-item-avatar>
            <v-img
                class="rounded"
                id="profile-img"
                :src="imageReactive"
            >

            </v-img>

          <v-icon small v-if="!image" style="opacity:0.2;position:absolute;top:50%;transform: translateY(-50%)">mdi-camera</v-icon>

            <input type="file"
                   class="file-input"
                   style="opacity:0;width:100%;height:100%;position: absolute;top:0;left:0"
                   @change="setImage"
                   accept="image/png, image/jpeg,image/bmp"
            />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-text-field :value="name" @change="updateName" placeholder="Gruppenbetreff eingeben..."/>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-layout wrap class="px-5">
      <v-flex xs12>

        <small>Mitglieder: {{participantsIds.length}}</small>

      </v-flex>
      <v-flex shrink v-for="user of participants" :key="user.id">
      <v-sheet color="transparant" class="d-flex justify-center flex-wrap py-5" width="75px" max-width="75px">
        <v-avatar  width="45px" height="45px">

          <v-img :src="user.profilePic?'data:image/png;base64,'+user.profilePic.file : 'https://ui-avatars.com/api/?name='+user.firstName+'+'+user.lastName"
          ></v-img>

        </v-avatar>
        <small class="text-truncate text-center" v-html="user.firstName+' '+user.lastName"></small>
      </v-sheet>
      </v-flex>
    </v-layout>
    <ImageCropper :has-image="img!=null" :img="img" @updateImage="updateImage"/>
  </v-card>
</template>

<script>
import ImageCropper from "@/views/ImageCropper";
import UploadFile from "@/services/upload-file";
export default {
  name: "ApplyGroupInfo",
  components: {ImageCropper},
  props:{
    image:Object,
    name:String,
    participantsIds:Array,
  },
  data(){
    return{
      img:null
    }
  },
  computed: {
    participants() {
      return this.$store.state.app.allUsers.filter(x => this.participantsIds.includes(x.id));
    },
    imageReactive(){
      return this.image?'data:image/png;base64,'+this.image.file : 'https://ui-avatars.com/api/?name='+this.name;
    }
  },
  methods:{
    stepBack(){
      this.$emit('stepBack');
    },
    updateName(name){
      this.$emit('updateName',name);
    },
    setImage: function (e) {
      this.hasImage = true
      let files = e.target.files || e.dataTransfer.files;
      if(!files.length) return;
      else this.img = URL.createObjectURL(files[0]);

    },
    updateImage(image){
      UploadFile(image).then(response => {
        this.$emit('updateImage',response.data);
      });

      this.$store.dispatch("loading/success");
      this.img=null;
    }
  }
}
</script>

<style scoped>

</style>