<template>
  <v-dialog
      v-model="dialog"
      fullscreen
  >
    <template v-if="!speedDial" v-slot:activator="{ on: menu, attrs }">
      <v-tooltip left v-bind="attrs"
                 v-on="on">
        <template v-slot:activator="{ on: tooltip}">
      <v-btn
          :style="{ 'right': position.right + 'px', 'bottom': position.bottom + 'px' }"
          style="position:fixed;"
          fab
          color="primary"
          class="text-secondary"
          v-bind="attrs"
          v-on="{ ...tooltip, ...menu }"
      >
        <v-icon small>mdi-message-reply-text</v-icon>
      </v-btn>
        </template>
        Neuen Chat öffnen
      </v-tooltip>
    </template>
    <v-card >

    <v-toolbar flat height="60px" dense>
      <v-btn @click="dialog=false" icon><v-icon>mdi-close</v-icon></v-btn>
    <v-spacer></v-spacer>
      <v-toolbar-title>Neuen Chat öffnen</v-toolbar-title>
      <v-spacer></v-spacer>

    </v-toolbar>
      <v-text-field v-model="searchQuery" prepend-inner-icon="mdi-magnify" placeholder="Suchen..." class="mx-5" hide-details dense outlined></v-text-field>
        <v-list
        >
          <div  v-for="user of filteredUsers" :key="user.id" @click="openNewChat(user.id)">
          <v-list-item link

          >
            <v-list-item-avatar>
              <v-avatar>
                <v-img :src="user.profilePic?'data:image/png;base64,'+user.profilePic.file :'//ssl.gstatic.com/accounts/ui/avatar_2x.png'"></v-img>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="user.firstName+' '+user.lastName"></v-list-item-title>
              <v-list-item-subtitle v-html="user.email"></v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>
          <v-divider inset/>
          </div>
        </v-list>
</v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";
import {loadContacts} from "@/services/chat.service";
import {NotifiyClient} from "@/models/notification";

export default {
  name: "AddNewChat",
  props:{
    speedShow:Boolean,
    speedDial:Boolean
  },
  data(){
    return{
      dialog:false,
      newUser:null,
      users:[],
      searchQuery:""
    }
  },
  watch:{
    speedShow(){
      this.dialog=this.speedShow
    }
  },
  created() {
    this.loadContacts();
  },
  computed:{
    position(){
      if(this.$vuetify.breakpoint.mdAndUp){
        return { right:270,bottom:15}
      }else{
        return { right:15,bottom:75}
      }

    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    filteredUsers(){
      let users= this.users;
      users=users.filter(user => user.id !== this.currentUser.id);
      users= users.filter(user => (user.firstName+' '+user.lastName).includes(this.searchQuery) || user.username.includes(this.searchQuery))

      return users;

    },
  },

  methods: {
    loadContacts() {
      ApiService.get("user/getAll").then((users) => {
        this.users = users.data;
      });
    },
    openNewChat(user){
      if(this.currentUser.currentChatPartners && !this.currentUser.currentChatPartners.includes(user)){
        this.currentUser.currentChatPartners.push(user);

        loadContacts();
        this.$store.dispatch("chat/loadAllChats");

        this.updateUser();
        this.dialog=false;
      }else{
        this.$store.dispatch("notificationsStore/add",new NotifiyClient("warning","Sie haben bereits einen Chat mit dieser Person.",false,"","/chat/user/"+user,"Chat öffnen"))
      }

    },
    updateUser(){
      let self=this;
      ApiService.post("user/update",this.currentUser).then(response => {
        let user =JSON.parse(localStorage.getItem("user"));
        let merged = {...user, ...response.data};
        this.$store.dispatch("auth/updateLocalUser",merged);
        self.$store.dispatch("loading/success");
      });
    },
  }
}
</script>

<style scoped>

</style>