<template>
  <div>
  <v-menu
      left
      bottom
      offset-y
      :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">

      <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          small
      >
        <v-badge
            :value="unreadMessages.length>0"
            color="error"
            :content="unreadMessages.length"
            overlap>
          <v-icon ref="bell" class="animate__animated animate__repeat-2">mdi-bell</v-icon>
        </v-badge>
      </v-btn>

    </template>

    <v-list max-height="500px" max-width="100%" class="pa-5 overflow-y-auto min-width-600">
      <h3 class="font-weight-regular">Benachrichtigungen</h3>
      <small class="d-flex justify-space-between mb-1"><span class="caption">Letzte</span><a text class="caption" @click="deleteAll">Alle löschen</a></small>
      <Notification v-for="notification of sortedNotifications"
                    :key="notification.id"
                    @afterNotificationRead="getNotifications"
                    :notification="notification"
                    @update="update"
      />
    </v-list>
  </v-menu>
  </div>
</template>
<script>
import ApiService from "@/services/api.service";
import Notification from "@/views/Notifications/Notification";

export default {
  name: 'NotificationsView',
  components: {Notification},
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    unreadMessages(){
      return this.notifications.filter(n => n.opened == false)
    },
    sortedNotifications(){
      return  [...this.notifications].sort(function(x, y){
        return new Date(y.created_at) - new Date(x.created_at);
      })
    },

  },
  mounted(){
    if(this.currentUser) {
      this.getNotifications();

      window.setInterval(() => {
        this.getNotifications()
      }, 5000)
    }
    },
  data(){
    return{
      notifications:[],
    }
  },
  methods: {

    deleteAll(){
      let self=this;
      for(let notification of self.notifications){

        ApiService.post("notifications/delete",notification).then(function(){
          self.update(notification);
        })
      }

    },
    update(notification){
     this.notifications.splice(this.notifications.findIndex(n => n.id === notification.id),1);
    },
    getNotifications() {
      let self = this;
      if(self.notifications.length>0 && self.$refs.bell) self.$refs.bell.$el.classList.remove("animate__swing");
      let old= self.notifications.length;
      ApiService.postSilent("notifications/get", {content: self.currentUser.id}).then(response => {
        self.notifications = response.data;

        if(old<self.notifications.length) {

          if(self.$refs.bell) self.$refs.bell.$el.classList.add("animate__swing");

        }
      })
    },
  }
}
</script>
