<template>
  <v-list-item @click="openChat">
    <v-list-item-avatar width="45px" height="45px" class="rounded-circle">

      <Avatar v-if="chat.image" :file="chat.image" :name="chat.name"/>
      <Avatar v-else :file="chat.profilePic" :name="chat.name"/>

    </v-list-item-avatar>
    <v-list-item-content>

      <v-badge
          v-if="chat.unreadMessages>0"
          bordered
          color="success"
          :content="chat.unreadMessages"
          overlap
          offset-y="15px"
          offset-x="20px"
      >
        <v-list-item-title><span v-html="chat.name"></span><RoleBadge v-if="chat.email" :roles="chat.roles" /></v-list-item-title>
      </v-badge>

      <v-list-item-title v-else  ><span v-html="chat.name"></span><RoleBadge v-if="chat.email" :roles="chat.roles" /></v-list-item-title>
      <v-list-item-subtitle><span v-if="chat.lastMessage"><span v-if="chat.lastMessage.senderId!=currentUser.id" v-html="chat.lastMessage.senderName.split(' ')[0]+': '"></span><span v-else>Du: </span><span v-if="chat.lastMessage.messageType===1"><v-icon small>mdi-image</v-icon></span><span v-else-if="chat.lastMessage.messageType===2"><v-icon small>mdi-file-pdf-box</v-icon></span><span v-html="chat.lastMessage.content"></span></span><span v-else >-</span></v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon v-if="!small">
      <v-icon>mdi-chevron-right</v-icon>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import Avatar from "@/views/Avatar";
import RoleBadge from "@/views/RoleBadge";
export default {
  name: "ChatButtonUniversal",
  components: {RoleBadge, Avatar},
  props:{
    name:String,
    chat:Object,
    small:Boolean

  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
  methods:{
    openChat(){
      if(this.chat.email){
        this.$router.push('/chat/user/'+this.chat.id)
      }else{
        this.$router.push('/chat/gruppe/'+this.chat.id)
      }
    }
  }
}
</script>

<style scoped>

</style>