<template>
<v-dialog persistent v-model="show" fullscreen>
  <v-card flat class="px-5 pt-5 d-flex justify-center align-center">
    <v-btn @click="$router.back()" style="position:absolute;top:0;left:0;" icon><v-icon>mdi-close</v-icon></v-btn>

    <div>
      <v-img class="mx-auto" width="200px" :src="require('../assets/3655_9_1_m.png')"></v-img>
      <v-card-title class="d-flex justify-center"><span style="width:100%" class="text-center">Willkommen</span> </v-card-title>

    <v-form style="max-width: 400px" ref="form" class="mx-5 mx-md-auto d-flex justify-center flex-wrap" v-model="valid">
      <v-text-field
          solo
          rounded
          autofocus
          style="width:200px"
          label="Email Address"
          type="email"
          v-model="user.email"
          required
      />
      <v-text-field label="Password"
                    v-model="user.password"
                    style="width:100%"
                    solo
                    rounded
                    @keypress.enter="handleLogin"
                    :rules="passwordRules"
                    type="password"
                    required
      />

      <v-btn class="mx-auto align-self-center" :loading="loading" block :disabled="!valid" @click="handleLogin">
        <span>Login</span>
      </v-btn>
      <v-btn color="accent" class=" d-none mx-auto my-1 align-self-center" :loading="loading" block :href="'https://bdme.auth.eu-central-1.amazoncognito.com/login?client_id=6fb837a28n4589dkr8r5c9n6vb&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri='+frontendhost+'/login'">
        <span>Sign in with Cognito</span>
      </v-btn>
      <v-alert type="error" class="ma-3" v-if="message" dismissible color="red">{{message}}</v-alert>
    </v-form>
     </div>
  </v-card>
</v-dialog>
</template>

<script>

export default {
  name: 'LoginView',
  data() {
    return {
      show:true,
      valid: true,
      user: {
        email:'',
        password:''
      },
      message: '',
      loading:false,
      username:'',
      usernameRules:[
        v => !!v || this.$t('login.nameRequired'),
        v => (v && v.length <= 20) || this.$t('login.nameLength'),
      ],
      password:'',
      confirmPassword:"",
      passwordRules:
          [
            v => !!v || this.$t('login.passwordRequired'),

          ]

    };
  },
  computed: {
    frontendhost(){
      return process.env.VUE_APP_FRONTHOST;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
  methods: {

    handleLogin() {
      this.loading = true;
      /**
       this.$validator.validateAll().then(isValid => {
                    if (!isValid) {
                        this.loading = false;
                        return;
                    }
       **/
      if (this.user.email && this.user.password) {
        this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/profile');
            },
            () => {
              this.loading = false;
              this.message = "You entered the wrong email or password"
            }
        );
      }

    }
  }
};
</script>
<style>

</style>