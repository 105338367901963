<template>



  <v-list-item ref="notificationObject"  @mouseleave="setOpened" class="animate__animated rounded hvr-grey hvr-item-delete" style="cursor:pointer;overflow: hidden">
    <v-list-item-avatar>
      <v-img :src="user && user.profilePic?'data:image/png;base64,'+user.profilePic :'//ssl.gstatic.com/accounts/ui/avatar_2x.png'"></v-img>
    </v-list-item-avatar>
   <v-list-item-content >
    <v-list-item-title style="font-size: 0.8rem;">
      <v-slide-group>
        <v-slide-item>
          <span>

      <span  v-html="notification.message"></span> <span class="text-grey" v-html="date"></span>

    </span>
        </v-slide-item>
      </v-slide-group>
    </v-list-item-title>
     <v-sheet v-if="!opened" style="position: absolute;right:0" class="mr-5 animate__animated animate__pulse animate__infinite" width="7px" height="7px" rounded color="dark1"></v-sheet>

   </v-list-item-content>
    <v-sheet style="position:absolute;" class="item-delete d-flex align-center justify-center" width="50px" height="100%" color="accent">
      <v-btn class="mx-auto" style="z-index:99" @click="deleteNotification"  icon dark><v-icon>mdi-delete</v-icon></v-btn>
    </v-sheet>
    <v-sheet v-if="notification.link" style="position:absolute;" class="item-open d-flex align-center justify-center" width="50px" height="100%" color="accent">
      <v-btn class="mx-auto" style="z-index:99" @click="goTo(notification.link)"   icon dark><v-icon>mdi-link</v-icon></v-btn>
    </v-sheet>
  </v-list-item>
</template>
<script>
import ApiService from "@/services/api.service"
import moment from "moment";
export default {
  name: 'NotificationItem',
  data(){
    return{
    user:null,
      opened:this.notification.opened
    }
  },
  props: {
    notification: {},
  },
  computed:{
    date(){
      moment.locale('de');
      return moment(this.notification.created_at).fromNow();
    },

  },
  watch:{
    notification(){
      let self=this;
      if(self.notification.fromUserId){
      ApiService.get("user/get?id="+self.notification.fromUserId).then(response => {
        self.user=response.data;
      })
      }
    }
  },
  methods:{
    goTo(link){
      this.$router.push(link)
    },
    deleteNotification(){
      let self=this;
      self.$refs.notificationObject.$el.classList.add("animate__hinge");
      ApiService.post("notifications/delete",self.notification).then(response => {
        setTimeout(() => {
          if(response.data) self.$emit("update",self.notification)
      },2000)

      });
    },
    setOpened(){
      let self=this;
      if(!self.notification.opened) {
        ApiService.post("notifications/read", self.notification).then(response => {
          self.opened = response.data.opened;
          self.$emit("afterNotificationRead");
        });
      }
    }
  }
}
</script>
<style>
.item-delete{
  right:-50px;
  transition: right 0.5s ease-in-out;
}
.item-open{
  left:-50px;
  transition: left 0.5s ease-in-out;
}
.hvr-item-delete:hover .item-open{
  left:0px;
}
.hvr-item-delete:hover .item-delete {
  right: 0px;
}
</style>