var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticStyle:{"max-height":"100%"},attrs:{"content-class":"overflow-hidden","fullscreen":"","max-width":"100%"},model:{value:(_vm.hasImageC),callback:function ($$v) {_vm.hasImageC=$$v},expression:"hasImageC"}},[_c('v-card',[_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.hasImageC=false}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","loading":_vm.$store.state.loading.loadingState},on:{"click":_vm.crop}},[_vm._v("Upload")])],1),_c('cropper',{ref:"cropper",staticClass:"upload-example-cropper",staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.img,"stencil-props":{
		handlers: {},
		movable: false,
		scalable: false,
	},"stencil-size":{
		width: 280,
		height: 280
	},"image-restriction":"stencil","stencil-component":_vm.$options.components.Stencil}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }