import ApiService from '@/services/api.service'

export function dataURItoBlob(dataURI) {
// convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);
// separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
// write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
}

export async function getAsByteArray(file) {
    return new Uint8Array(await readFile(file))
}
export function getFileSize(base64String){
    var stringLength = base64String.length - 'data:image/png;base64,'.length;

    var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
    var sizeInKb=sizeInBytes/1000;
    var sizeInMB=sizeInKb*0.0009765625;
    return sizeInMB;
}
export function readFile(file) {
    return new Promise((resolve, reject) => {
        // Create file reader
        let reader = new FileReader()

        // Register event listeners
        reader.addEventListener("loadend", e => resolve(e.target.result))
        reader.addEventListener("error", reject)

        // Read file
        reader.readAsArrayBuffer(file)
    })
}
export function getBytesFromFile(file){
    const formData = new FormData();
    formData.append("file", file);
    return ApiService.post("file/getBytes",formData).then(response => {
        return Promise.resolve(response.data);
    })
}
export function deleteFile(file){
    return ApiService.post("file/delete",file).then(response => {
        if(response.status===200){
            console.log("Old File deleted");
        }
    })
}
export function uInt8ArrayToBlob(byteArray,type){
    return new Blob([new Uint8Array(byteArray)], {type: type});
}
export function toBase64(file){
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
export function downloadBlobFile(byteArray,type, name = 'file.txt') {
    const blobUrl = 'data:'+type+';base64,'+byteArray

    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        })
    );
}