<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="openC"

  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          v-bind="attrs"
          fab
          v-on="on"
      ><v-icon>mdi-plus</v-icon></v-btn>
    </template>
    <v-card class="">
      <v-card-title v-if="id" class="grey">Beitrag bearbeiten</v-card-title>
      <v-card-title v-else class="grey">Neuen Beitrag veröffentlichen</v-card-title>
      <v-form class="px-5 py-5">
        <v-select outlined label="Kategorie" :items="categories" v-model="news.category" placeholder="Kategorie"></v-select>
        <v-text-field outlined label="Titel" v-model="news.title" placeholder="Titel" />
        <VueEditor v-model="news.content"/>
      </v-form>
      <v-card-actions>
        <v-btn :loading="$store.state.loading.loadingState" @click="save" text>Veröffentlichen</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
</template>

<script>
import ApiService from '@/services/api.service'
export default {
  name: "AdminNewsAddNew",
  data(){
    return{
      news:Object,
      categories:['FAQ','Information'],
    }
  },
  created(){
    this.news={};
    if(this.id) this.getNewsToEdit();
  },
  watch:{
    open(){
      if(!this.id)this.news={};
      else this.getNewsToEdit();
    },
    id(){
      this.getNewsToEdit();
    }
  },
  props:{
    id:Number,
    open:Boolean
  },
  computed:{
    openC:{
      get(){
        return this.open;
      },set(value){
        this.$emit("update",value);
      }
    }
  },
  methods:{
    getNewsToEdit(){
      let self=this;
      ApiService.get("news/get/"+this.id).then(
          response => {
            self.news=response.data;
          }
      )
    },
    save(){
      this.$store.dispatch("loading/toggle",true)
      this.$store.dispatch("news/save",this.news).then(()=>{
        this.openC=false;
      });

    }
  }
}
</script>

<style scoped>

</style>