<template>
<v-list>
  <ChatButtonUniversal v-for="chat of allChats" :key="chat.id" :chat="chat"/>
  <v-speed-dial
      v-model="fab"
      fixed
      right
      bottom
      :style="{right:createButtonLeft+'px',bottom:createButtonBottom+'px'}"
      direction="top"

      :open-on-hover="false"
      :transition="transition"
  >
    <template v-slot:activator>
      <v-btn
          v-model="fab"
          color="blue darken-2"
          dark
          fab
      >
        <v-icon v-if="fab">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-message
        </v-icon>
      </v-btn>
    </template>
    <v-btn

        color="accent"
        @click="showCreateGroup=!showCreateGroup"
        fab
        small

    ><v-icon>mdi-account-multiple-plus</v-icon></v-btn>
    <v-btn
        fab
        dark
        small
        @click="showAddChat=!showAddChat"
        color="indigo"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

  </v-speed-dial>
  <CreateChatRoom :speed-show="showCreateGroup" speed-dial/>
  <AddNewChat :speed-show="showAddChat" speed-dial/>
</v-list>
</template>

<script>
//TODO Filter only Group and 1:1 Chats
import ChatButtonUniversal from "@/views/Chat/ChatButtonUniversal";
import CreateChatRoom from "@/views/Chat/CreateChatRoom";
import AddNewChat from "@/views/Chat/AddNewChat";
export default {
  name: "ChatListUniversal",
  components: {AddNewChat, CreateChatRoom, ChatButtonUniversal},
  data(){
    return{
      hover:false,
      transition: 'slide-y-reverse-transition',
      fab:false,
      showCreateGroup:false,
      showAddChat:false,
    }
  },
  created() {
    this.$store.dispatch("chat/loadAllChats",this.currentUser.currentChatPartners);

  },
  computed:{
    allChats(){
      if(this.$store.state.chat.showChats==='group'){
        return this.$store.state.chat.groupChats
      }else if(this.$store.state.chat.showChats==='single'){
        return this.$store.state.chat.userChats;
      }else{
        return this.$store.state.chat.allChats;
      }

    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    createButtonLeft(){
      if(this.$vuetify.breakpoint.mdAndUp){
        return 275;
      }else return 15;
    },
    createButtonBottom(){
      if(this.$vuetify.breakpoint.mdAndUp){
        return 15;
      }else return 75;
    }
  },
}
</script>

<style scoped>

</style>