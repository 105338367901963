<template>
  <div class="ma-xs-0 ma-md-5">
<v-app-bar app color="secondary" flat class="rounded-md">
  <v-btn @click="$router.back()" icon ><v-icon>mdi-arrow-left</v-icon></v-btn>
  <div class="d-flex justify-space-between align-center" style="width:100%" v-if="$route.name==='Chats'">
    <v-app-bar-title>Chats</v-app-bar-title>
    <v-spacer></v-spacer>
  <v-chip-group active-class="chip-active" v-model="$store.state.chat.showChats">
    <v-chip value="all">
      Alle
    </v-chip>
    <v-chip value="single">
      Chats
    </v-chip>
    <v-chip  value="group">
      Gruppen
    </v-chip>

  </v-chip-group>
  </div>
  <ChatItemTopBar v-else-if="$route.name==='activeChat'"/>
  <PayrollTopBar v-else-if="$route.name==='Gehaltsabrechnungen'"/>
  <CalendarTopBar v-else-if="$route.name==='Kalender'"/>
  <v-text-field v-else class="shrink" dense hide-details outlined append-icon="mdi-magnify" label="Suchen"></v-text-field>
  <v-spacer/>
  <Notifications />
  <v-app-bar-nav-icon class="hidden-md-and-up" @click="mobileDrawer=!mobileDrawer"></v-app-bar-nav-icon>



</v-app-bar>
    <v-progress-linear style="position:fixed;z-index:9999;left:0;top:0;" v-if="$store.state.loading.loadingState || $store.state.loading.success.state"
                       :indeterminate="!$store.state.loading.success.state"
                       class="animate__animated"      :class="{'animate__fadeOutUp':$store.state.loading.success.animateOut,'bg-success':$store.state.loading.success.state=='success','bg-error':$store.state.loading.success.state=='error'}"
                       color="accent"
    ></v-progress-linear>
    <v-navigation-drawer
        absolute
        temporary
        v-model="mobileDrawer"
    >
      <v-list

          nav
          dense
      >
        <v-list-item-group
            active-class="deep-purple--text text--accent-4"
        >
          <v-list-item
              v-for="item in $store.state.app.navItems"
              :key="item.title"
              link
              :href="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
              v-if="$store.state.auth.user.roles.includes('ROLE_ADMIN')"
              link
              href="/admin"
          >
            <v-list-item-icon>
              <v-icon>mdi-lock-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Administrator</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
              v-if="$store.state.auth.user.roles.includes('ROLE_ADMIN')"
              link
              href="/payroll"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-cash-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Gehaltsabrechnungen</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>

        <v-list-item color="red" link @click="$router.push('/logout')">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-title>
            Logout
          </v-list-item-title>
        </v-list-item>

      </v-list>

    </v-navigation-drawer>
    </div>
</template>


<script>
import ChatItemTopBar from "@/views/Navigation/ChatItemTopBar";
import PayrollTopBar from "@/views/Navigation/PayrollTopBar";
import Notifications from "@/views/Notifications/Notifications";
import CalendarTopBar from "@/views/Navigation/CalendarTopBar";

export default {
  name: "TopBar",
  data(){
    return{
      mobileDrawer:false,
      drawer:false,

      showChats:"all",
      }
  },
  components:{
    CalendarTopBar,
    PayrollTopBar,
    ChatItemTopBar,
    Notifications
  },
  computed:{

    chatPartners(){
      return this.$store.state.chat.currentChatPartners
    },
    unreadMessages(){
      const sum = this.chatPartners.reduce((accumulator, object) => {
        return accumulator + object.newMessages;
      }, 0);
      return sum;
    }
  },
  methods:{
    //TODO CHECK IF USE RIS ADMIN - Only then he can manage the participants




    gotTo(target){
      this.$router.push("/"+target);
    },
  }
}
</script>

