var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-1",staticStyle:{"width":"100%"}},[_c('v-row',{staticClass:"fill-height"},[_c('v-col',[(_vm.small)?_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" Heute ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[(_vm.calendar.type==='day')?_c('v-icon',[_vm._v("mdi-calendar")]):_vm._e(),(_vm.calendar.type==='month')?_c('v-icon',[_vm._v("mdi-calendar-month")]):_vm._e(),(_vm.calendar.type==='week')?_c('v-icon',[_vm._v("mdi-calendar-week")]):_vm._e(),_c('v-select',{staticClass:"ma-2 shrink",staticStyle:{"width":"100%","opacity":"0","position":"absolute"},attrs:{"light":"","items":[{
        text:'Monat',
        value: 'month'},
        {
        text:'Woche',
        value: 'week'},
        {
        text:'Tag',
        value: 'day'}],"dense":"","outlined":"","hide-details":""},model:{value:(_vm.calendar.type),callback:function ($$v) {_vm.$set(_vm.calendar, "type", $$v)},expression:"calendar.type"}})],1)],1)],1):_vm._e(),_c('v-sheet',{staticStyle:{"position":"relative"},attrs:{"height":_vm.calendarHeight}},[_c('v-calendar',{ref:"calendar",staticStyle:{"width":"100%","height":"100%"},attrs:{"event-height":_vm.eventHeight,"color":"transparent","weekdays":_vm.weekday,"type":_vm.$store.state.navigation.calendar.type,"events":_vm.filteredEvents,"event-overlap-mode":_vm.mode,"event-overlap-threshold":30,"event-color":_vm.getEventColor},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay},scopedSlots:_vm._u([{key:"event",fn:function({ event }){return [_c('v-layout',{ref:'event'+event.id,staticClass:"px-1 v-event-draggable",attrs:{"justify-space-between":""}},[_c('v-flex',{attrs:{"xs9":""}},[_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(event.title))])]),_c('v-flex',{attrs:{"xs3":"","align-self-center":""}},[(event.timed)?_c('div',{staticClass:"d-flex flex-wrap justify-center align-center",staticStyle:{"font-size":"0.4rem"}},[_c('div',{staticStyle:{"line-height":"7px"}},[_vm._v(_vm._s(_vm.formatEventTime(event.start))+" -")]),_c('div',{staticStyle:{"line-height":"7px","margin-right":"2px"}},[_vm._v(_vm._s(_vm.formatEventTime(event.end)))])]):_vm._e()])],1)]}}]),model:{value:(_vm.$store.state.calendar.value),callback:function ($$v) {_vm.$set(_vm.$store.state.calendar, "value", $$v)},expression:"$store.state.calendar.value"}}),(_vm.selectedEvent)?_c('TerminDetails',{attrs:{"selected-element":_vm.selectedElement,"host":_vm.selectedEvent.userId === _vm.currentUser.id,"selected-event":_vm.selectedEvent,"selected-open-prop":_vm.selectedOpen},on:{"updateSelectedEvent":_vm.updateSelectedEvent}}):_vm._e(),_c('AddTermin')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }