<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="openC"

  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          v-bind="attrs"
          fab
          v-on="on"
      ><v-icon>mdi-plus</v-icon></v-btn>
    </template>
    <v-card class="">
      <v-card-title v-if="id" class="grey">Mitarbeiter bearbeiten</v-card-title>
      <v-card-title v-else class="grey">Neuen Mitarbeiter anlegen</v-card-title>
      <v-form class="px-5 py-5">
        <v-layout wrap>
        <v-flex xs12>
          <SelectUserRole :user="user"/>
        </v-flex>
          <v-flex xs6 class="pr-5">
            <v-text-field outlined label="Vorname" v-model="user.firstName" placeholder="Vorname"/>
          </v-flex>
          <v-flex xs6>
            <v-text-field outlined label="Nachname" v-model="user.lastName" placeholder="Nachname"/>
          </v-flex>
          <v-flex xs12>
        <v-text-field hint="An diese Email wird eine Bestätigungsemail & das initiale Passwort gesendet." outlined label="Email" v-model="user.email" placeholder="Email"/>
          </v-flex>
        </v-layout>
      </v-form>
      <v-card-actions>
        <v-btn :loading="$store.state.loading.loadingState" @click="save" text>Anlegen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from '@/services/api.service'
import SelectUserRole from "@/views/SelectUserRole";
export default {
  name: "AdminUserAddNew",
  components: {SelectUserRole},
  data(){
    return{
      user:{
        firstName:null,
        lastName:null,
        email:null,
        role:[]
      },
      roles:['admin','user'],
    }
  },
  computed:{
    openC:{
      get(){
        return this.open;
      },set(value){
        this.$emit("update",value);
      }
    }
  },
  created(){
    this.user={};
    if(this.id) this.getUserToEdit();
  },
  watch:{
    open(){
      if(!this.id)this.news={};
      else this.getUserToEdit();
    },
    id(){
      this.getUserToEdit();
    }
  },
  props:{
    id:Number,
    open:Boolean
  },
  methods:{
    getUserToEdit(){
      let self=this;
      ApiService.get("news/get/"+this.id).then(
          response => {
            self.news=response.data;
          }
      )
    },
    save(){
      console.log("User",this.user);
      let self=this;
      this.$store.dispatch("loading/toggle",true)
      ApiService.post("user/create",this.user).then(response => {
        console.log("User",response.data);
        self.$store.dispatch("loading/success");
      })

    }
  }
}
</script>

<style scoped>

</style>