<template>
<v-layout wrap class="py-5">
<h3>Formulare</h3>
  <v-flex xs12>
  <v-sheet class="my-5 px-5 py-2" color="grey">
    <h4>Hier für alle Formulare Dateien hochladen</h4>
    <v-layout wrap justify-start>
      <v-flex xs3>
        <v-sheet v-ripple style="width:200px;height:125px;position: relative" class="d-flex flex-wrap justify-center pa-5 my-5 mr-5"><v-icon large>mdi-plus</v-icon><div style="pointer-events: none" class="text-center text--disabled" ><span v-if="formObjectUploadPipe.length>0">Weitere Dateien hinzufügen</span><span v-else>Dateien hochladen</span></div>
          <input type="file"
                 multiple
                 class="file-input"
                 style="opacity:0;width:100%;height:100%;position: absolute;top:0;left:0"
                 @change="uploadFiles"
          />
        </v-sheet>
      </v-flex>
      <v-flex xs3>
        <v-sheet v-if="formObjectUploadPipe.length>0" @click="saveBulk" v-ripple style="width:200px;height:125px" class="d-flex flex-wrap justify-center pa-5 my-5 mr-5"><v-icon large>mdi-upload</v-icon><div style="pointer-events: none" class="text-center text--disabled" >Jetzt final hochladen</div></v-sheet>
      </v-flex>
      <v-flex xs6 class="my-5 d-flex flex-wrap justify-start">
        <UploadPreview  v-for="(fileObject,$index) of formObjectUploadPipe" @remove="removeFileFromUploadPipe" :key="$index" :fileObject="fileObject" :index="$index"/>
      </v-flex>
    </v-layout>
  </v-sheet>
  </v-flex>

  <v-flex xs12>
    <v-expansion-panels
        flat

        v-model="panels"
        multiple
    >
      <v-expansion-panel class="elevation-0 grey" v-for="prop in $store.state.forms.allGroupedByCategory" :key="prop" >
        <v-expansion-panel-header v-html="prop[0].category"></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-layout wrap>
            <v-flex v-for="form of prop" :key="form.id" class="pa-2" xs12 md6>
              <FormItem  :form-item="form"></FormItem>

            </v-flex>
          </v-layout>
           </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
  </v-flex>

</v-layout>
</template>

<script>
import UploadPreview from "@/views/Forms/UploadPreview";
import UploadFile from "@/services/upload-file";
import FormItem from "@/views/Forms/FormItem";
//import {getAsByteArray} from "@/services/file.service";
export default {
  name: "FormList",
  data(){
    return{
      formObjectUploadPipe:[],
    }
  },
  computed:{
    panels(){
      let length = Object.keys(this.$store.state.forms.allGroupedByCategory).length;
      let result=[]
      for (var i = 0; i < length; i++) {
        result.push(i);
        //Code segment that is executed COUNT times.
      }
      return result;
    }
  },
  created() {
    this.$store.dispatch("forms/getAllGroupedByCategory");
  },
  components: {FormItem, UploadPreview},
  methods:{
    removeFileFromUploadPipe(request){
     // console.log(request)
      this.formObjectUploadPipe.splice(request.index,1);
     // deleteFile(request.file);
    },
    uploadFiles(e){
      let files = e.target.files || e.dataTransfer.files;
      console.log(files);
      let self=this;
      for(let file of files) {
        UploadFile(file).then(response => {
          self.createFormObject(response.data);
        });
      }
    },
    saveBulk(){
      let self=this;
      console.log(this.formObjectUploadPipe);
      this.$store.dispatch("forms/bulkSave",{list:this.formObjectUploadPipe}).then(()=>{
        self.$store.dispatch("forms/getAllGroupedByCategory");
        self.formObjectUploadPipe=[];
      });
    },
    createFormObject(file){
      this.formObjectUploadPipe.push({id:file.id,title:null,description:null,file:file,category:null,link:null})
    },
    readAsByteArray(file){

      var reader = new FileReader();
      reader.onload = function() {

        var arrayBuffer = this.result,
            array = new Uint8Array(arrayBuffer),
            binaryString = String.fromCharCode.apply(null, array);

        console.log(binaryString);

      }
      return reader.readAsArrayBuffer(file);
    },
    createFormObjects(files){
      let result=[]
      for(let file of files){
        result.push({id:null,title:null,description:null,file:file,category:null,link:null});
      }
      return result;
    }
  }
}
</script>

<style scoped>

</style>