<template>
  <span>
  <v-chip class="ml-2" x-small v-if="roles.some(x=> x.id ===2)">Admin</v-chip>
  <v-chip class="ml-2" x-small v-if="roles.some(x=> x.id ===3)">HR</v-chip>
  </span>
</template>

<script>
export default {
  name: "RoleBadge",
  props:{
    roles:Array
  }
}
</script>

<style scoped>

</style>