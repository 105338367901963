<template>
  <v-dialog
      v-model="addTermin">
    <template v-slot:activator="{ on : menu }">
  <v-tooltip left>
    <template v-slot:activator="{on : tooltip}">
      <v-btn v-if="!edit"
          style="position:absolute;z-index:2;bottom:0;right:0"
          v-on="{ ...tooltip, ...menu }"
          color="primary"
          elevation="4"
          fab

      >
        <v-icon color="white">mdi-calendar-plus</v-icon>
      </v-btn>
      <v-list-item v-else v-on="{ ...tooltip, ...menu }" link>
        <v-list-item-avatar><v-icon>mdi-pencil</v-icon></v-list-item-avatar>
        <v-list-item-title>Bearbeiten</v-list-item-title>
      </v-list-item>
    </template>
    <span v-if="!edit">Termin hinzufügen</span>
    <span v-else>Termin bearbeiten</span>
  </v-tooltip>

    </template>
    <v-card>
      <v-toolbar
          dark
          dense
          color="primary"
      >
        <v-btn
            icon
            dark
            @click="addTermin = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="!edit">Termin hinzufügen</v-toolbar-title>
        <v-toolbar-title v-else>Termin bearbeiten</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              dark
              text
              @click="saveTermin"
              :disabled="start.time > end.time && end.time != '00:00'"
          >
            Speichern
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pa-10">
        <v-row>
          <v-layout>
          <v-flex shrink class="pa-2 d-flex justify-center">

            <v-menu
                ref="menuColor"
                v-model="menuColor"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-sheet width="50" height="50" class="rounded-circle"
                         :color="termin.color"

                    v-bind="attrs"
                    v-on="on"
                ></v-sheet>
              </template>
            <v-color-picker
                dot-size="25"
                v-model="termin.color"
                swatches-max-height="200"
                hide-canvas
                hide-sliders
                show-swatches
                hide-inputs
                :swatches="color.swatches"
                >
            </v-color-picker>
            </v-menu>
          </v-flex>
          <v-flex>
            <v-text-field v-model="termin.title" class="bigger-text-field" label="Betreff"></v-text-field>
          </v-flex>
          </v-layout>
        </v-row>
          <v-row>
          <v-col>
            <v-btn-toggle
                v-model="dateModel"
                borderless
            >
              <v-btn text small value="single">
                <span>eintägig</span>
              </v-btn>

              <v-btn text small value="multiple">
                <span >mehrtägig</span>

              </v-btn>
            </v-btn-toggle>
          </v-col>
          </v-row>

        <v-row>
          <v-col cols="1" class="d-flex justify-center">
            <v-icon>mdi-clock-outline</v-icon>
          </v-col>
          <v-col>

            <v-layout wrap>
              <v-flex shrink>
              <v-menu
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      text
                      v-html="computedStartDate"
                      v-bind="attrs"
                      v-on="on"
                  ></v-btn>
                </template>
                <v-date-picker
                    v-model="start.date"
                    @input="menuStartDate = false"
                ></v-date-picker>
              </v-menu>
              </v-flex>
              <v-flex shrink>
              <v-menu
                  v-if="!allDay"
                  ref="menu"
                  v-model="menuStartTime"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="start.time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-html="start.time"
                      text
                      v-bind="attrs"
                      v-on="on"
                  ></v-btn>
                </template>
                <v-time-picker
                    no-title
                    format="24h"
                    v-if="menuStartTime"
                    v-model="start.time"
                    full-width
                    @click:minute="$refs.menu.save(start.time)"
                ></v-time-picker>
              </v-menu>
              </v-flex>
              <v-flex v-if="!allDay" shrink class="d-flex align-center">
              <v-icon small>mdi-minus</v-icon>
              </v-flex>
              <v-flex shrink>
              <v-menu
                  v-model="menuEndDate"
                  v-if="dateModel==='multiple'"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      text
                      v-html="computedEndDate"
                      v-bind="attrs"
                      v-on="on"
                  ></v-btn>
                </template>
                <v-date-picker
                    v-model="end.date"
                    @input="menuEndDate = false"
                ></v-date-picker>
              </v-menu>
              </v-flex>
              <v-flex shrink>
              <v-menu
                  v-if="!allDay"
                  ref="menuEnd"
                  v-model="menuEndTime"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="end.time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-html="end.time"
                      text
                      :color="btnEndTimeBtnColor"
                      v-bind="attrs"
                      v-on="on"
                  ></v-btn>
                </template>
                <v-time-picker

                    format="24h"
                    ref="btnEndTime"
                    no-title
                    v-if="menuEndTime"
                    v-model="end.time"
                    full-width
                    @click:minute="$refs.menuEnd.save(end.time)"
                ></v-time-picker>
              </v-menu>
              </v-flex>
              <v-flex>
               <!-- <v-checkbox v-model="allDay" label="ganztägig" hide-details dense/>-->
              </v-flex>
            </v-layout>
            <small v-if="message" class="px-4" v-html="message"></small>
          </v-col>
        </v-row>
        <v-row>
          <v-col  class="d-flex justify-center" cols="1">
            <v-icon>mdi-account-multiple-outline</v-icon>
          </v-col>
          <v-col>
            <v-btn v-if="toggleGuests" @click="toggleGuests=!toggleGuests" text><small>Gäste hinzufügen</small></v-btn>

            <v-autocomplete
                v-else
                class="mx-5"
                autofocus
                small-chips
                deletable-chips
                multiple
                :items="$store.state.app.allUsers"
                item-text="email"
                item-value="id"
                v-model="termin.gaeste"
                :placeholder="'Gäste hinzufügen...'"
                :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                    disableKeys: true,
                    openOnClick: false,
                    maxHeight: 200
                  }"
            >

              <template v-slot:no-data >
                <div class="d-flex justify-space-between">
                <span class="mx-5">Keine Mitarbeiter gefunden...</span><v-btn text @click="getUsers" icon small><v-icon>mdi-reload</v-icon></v-btn>
                </div>
              </template>
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeGuest(data.item)"
                >
                  <v-avatar left>
                  <Avatar :file="data.item.profilePic" :name="data.item.firstName+' '+data.item.lastName"/>
                  </v-avatar>
                  {{ data.item.firstName }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <Avatar :file="data.item.profilePic" :name="data.item.firstName+' '+data.item.lastName"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.firstName+' '+data.item.lastName"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>

            </v-autocomplete>

          </v-col>
        </v-row>
        <v-row>
          <v-col  class="d-flex justify-center" cols="1">
            <v-icon>mdi-map-marker-outline</v-icon>
          </v-col>
          <v-col>
            <v-btn v-if="!toggleLocation && !termin.location" @click="toggleLocation=!toggleLocation" text><small>Ort hinzufügen</small></v-btn>
            <div v-else><v-text-field class="mx-5" v-model="termin.location"></v-text-field></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col  class="d-flex justify-center" cols="1">
            <v-icon>mdi-text</v-icon>
          </v-col>
          <v-col>
            <v-btn v-if="!toggleBeschreibung && !termin.beschreibung" @click="toggleBeschreibung=!toggleBeschreibung" text><small>Beschreibung hinzufügen</small></v-btn>
            <div v-else><VueEditor class="mx-5" :editor-toolbar="customToolbar" v-model="termin.beschreibung"/></div>
          </v-col>
        </v-row>
      <div>
      </div>
      </v-card-text>
    </v-card>

  </v-dialog>
</template>
<script>
import moment from "moment";
import ApiService from "@/services/api.service";
import Termin from "@/models/termin";
import stripScripts from "@/mixins/stripScripts";
import Avatar from "@/views/Avatar";
export default {
  name: 'AddTermin',
  components: {Avatar},
  props:{
    edit:Boolean,
    editTermin:Object
  },
  data(){
  return {
    allDay:false,
    color:{
      swatches:[ //Vordefinierte Farben für Terminfarben
          ["#f44336","#b71c1c","#c62828","#d32f2f"],
        ["#e91e63","#880e4f","#ad1457","#c2185b"],
        ["#9c27b0","#4a148c","#6a1b9a","#7b1fa2"],
        ["#673ab7","#311b92","#4527a0","#512da8"],
        ["#3f51b5","#1a237e","#283593","#303f9f"],
        ["#2196f3","#0d47a1","#1565c0","#1976d2"],
        ["#03a9f4","#01579b","#0277bd","#0288d1"],
        ["#00bcd4","#006064","#00838f","#0097a7"],
        ["#009688","#004d40","#00695c","#00796b"],
        ["#4caf50","#2e7d32","#388e3c","#43a047"],
        ["#8bc34a","#558b2f","#689f38","#7cb342"],
        ["#cddc39","#827717","#9e9d24","#afb42b"],
        ["#ffeb3b","#f57f17","#f9a825","#fbc02d"],
        ["#ffc107","#ff6f00","#ff8f00","#ffa000"],
        ["#ff9800","#e65100","#ef6c00","#f57c00"],
        ["#ff5722","#bf360c","#d84315","#e64a19"],
        ["#795548","#3e2723","#4e342e","#5d4037"],
        ["#607d8b","#263238","#37474f","#455a64"],
        ["#9e9e9e","#212121","#424242","#616161"],
      ]
    },

    menuColor:false, //show Color Selector
    timespan:[],
    termin:{        //Termin platzhalter
      color:"primary",
      title:"",
      gaeste:[],
      beschreibung:"",
      location:"",
      start:null,
      end:null,
      timed:true
    },
    customToolbar: [ //Toolbar des Rich Editors
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link","clean"]
    ],
    users:[], //Alle registrierten User für die Teilnehmerauswahl
    dateModel:"single", //Ob Termin 1 (single) oder mehrere (multiple) Tage dauert
    toggleBeschreibung:false, //Beschreibung hinzufügen im Formular
    toggleLocation:false,     //Ort hinzufügen im Formular
    toggleGuests:false,       //Teilnehmer hinzufügen im Formluar
    addTermin: false,         //Termin Dialog anzeigen
    menuStartTime: false,     //Startzeit Selector anzeigen
    menuStartDate:false,      //Startdatum Selector anzeigen
    menuEndDate:false,        //Enddatum Selector anzeigen
    menuEndTime:false,        //Endzeit Selector anzeigen
    message:null,             //Errormessage for die Zeitauswahl
    start: {                  //Platzhalter Startdatum+Zeit
      date:"",
      time:"00:00"
    },
    btnEndTimeBtnColor:"",    //Endzeit Button Farbe falls End<Start
    end: {                    //Platzhalter ENddatum+Zeit
      date:"",
      time:"00:00"
    },

  }
  },
  watch:{

    editTermin(){
      if(this.editTermin!=null){
        this.termin={...this.editTermin};
        this.populateDatePickers(this.editTermin);
      }
    },

    /*
      Generiere aus Datum+Zeit ein Datum & speichere es im entsprechenden Attribut der Immobilie
*/
    'start.date'(){
      this.termin.start=new Date(this.start.date+"T"+this.start.time);
      if(this.dateModel==="single"){
        this.termin.end=new Date(this.start.date+"T"+this.end.time);
      }
      if(this.dateModel==="multiple" && this.end.time){
        this.termin.end=new Date(this.end.date+"T"+this.end.time+":00");
      }else if(this.dateModel==="multiple"){
        this.termin.end=new Date(this.end.date+"T00:00");
      }
    },
    'end.date'(){
      this.termin.start=new Date(this.start.date+"T"+this.start.time);
      if(this.dateModel==="single"){
        this.termin.end=new Date(this.start.date+"T"+this.end.time);
      }
      if(this.dateModel==="multiple" && this.end.time){
        this.termin.end=new Date(this.end.date+"T"+this.end.time+":00");
      }else if(this.dateModel==="multiple"){
        this.termin.end=new Date(this.end.date+"T00:00");
      }
    },
    'start.time'(){
      this.termin.start=new Date(this.start.date+"T"+this.start.time+":00");
      if(this.dateModel==="single"){
        this.termin.end=new Date(this.start.date+"T"+this.end.time+":00");
      }
      if(this.dateModel==="multiple" && this.end.time){
        this.termin.end=new Date(this.end.date+"T"+this.end.time+":00");
      }else if(this.dateModel==="multiple"){
        this.termin.end=new Date(this.end.date+"T00:00");
      }

      if(this.start.time > this.end.time && this.end.time != "00:00"){
        this.btnEndTimeBtnColor="error"
        this.message="Der Endzeitpunkt kann nicht vor dem Startzeitpunkt liegen.";
      }
      else {

        this.message="";
        this.btnEndTimeBtnColor="";
      }
    },
    'end.time'(){
      this.termin.start=new Date(this.start.date+"T"+this.start.time);
      if(this.dateModel==="single"){
        this.termin.end=new Date(this.start.date+"T"+this.end.time);
      }
      else{
        this.termin.end=new Date(this.end.date+"T"+this.end.time);

      }
      if(this.start.time > this.end.time && this.end.time != "00:00"){
        this.btnEndTimeBtnColor="error"
        this.message="Der Endzeitpunkt kann nicht vor dem Startzeitpunkt liegen.";
      }
      else {
        this.message="";
        this.btnEndTimeBtnColor=""
      }
    }
  },
  computed:{
    /*
    Nutzerfreundlichere Darstellung der Daten
    */
    computedStartDate(){
      moment.locale('de');
      return moment(this.start.date).format('dddd[,] Do MMM');
    },computedEndDate(){
      moment.locale('de');
      return moment(this.end.date).format('dddd[,] Do MMM');
    }
  },

  created() {
    if(this.editTermin) {
      this.termin={...this.editTermin};
      this.populateDatePickers(this.editTermin);
    }
  },
  methods:{
    populateDatePickers(){
      const termin= {...this.termin}
      let start=[]
      if(termin.start) {
        start = termin.start.split(" ");
        this.start.date = start[0];
        this.start.time = start[1];
      }if(termin.end) {
        let end = termin.end.split(" ");
        this.end.date = end[0];
        this.end.time = end[1];
      }
    },
    getUsers(){
      this.$store.dispatch("app/getAllUsers");
    },
    /*
    Lösche User aus Liste der Teilnehmer
     */
    removeGuest(user){
      this.termin.gaeste.splice(this.termin.gaeste.indexOf(user),1)
    },
    /*
    Sende den Termin zum Server
    Schließe den Dialog
    Setzte die EIngabemaske auf null

    */
    saveTermin(){
      let self=this;
      if(self.termin.beschreibung) self.termin.beschreibung=stripScripts(self.termin.beschreibung);
      this.termin.timed=!this.allDay;
      ApiService.post("calendar/termin/save",self.termin).then(response => {
        let result = response.data;

        if(this.edit) this.$emit("updateSelectedEvent",self.termin);
        self.$store.dispatch("calendar/add",result);
        self.addTermin=false;
        self.termin=new Termin();

        self.start={
          date:"",
              time:"00:00"
        },
            self.end={
          date:"",
              time:"00:00"
        }
      });
    },

    /*
    Ungenutzte Funktion um alternative Zeitselectierung zu machen
    */
    populateTimeSelect(){
      this.timespan=[];
      for(let hour=0;hour<24;hour++){

        for(let minute=0;minute<60;minute=minute+15){
          let time="";
          if(hour<10&&minute==0) {
            time = "0" + hour + ":0" + minute;
          }
          else if(hour<10){
            time = "0"+hour+":"+minute;
          }
          else if(minute<10){
            time = hour+":0"+minute;
          }
          else{
            time=hour+":"+minute;
          }
          this.timespan.push(time)
        }
      }
    }
  }
}
</script>
