<template>
  <div>
  <v-menu v-if="!readonly" v-model="menu" offset-y :close-on-content-click="false">
    <template v-slot:activator="{attrs,on}">
      <v-chip dark :class="{'chip-error':fileObject.yearAndMonth==null,'success':fileObject.yearAndMonth!=null}" @click:close="remove" close v-bind="attrs" v-on="on"  class="mx-1">
        <span v-if="fileObject.yearAndMonth" v-html="betterYearMonth"></span>
        <span v-else>Monat wählen</span>

      </v-chip>
    </template>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon>
              mdi-file
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-html="fileObject.file.filename"></v-list-item-title>
            <v-list-item-subtitle v-html="fileObject.file.contentType"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-chip small v-html="fileSize"></v-chip>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <div class="d-flex justify-center">
      <v-date-picker
          @click:month="menu=false"
          class="mx-auto"
          v-model="fileObjectC.yearAndMonth"
          type="month"
          no-title
          scrollable
      ></v-date-picker>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="menu = false"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
    <v-tooltip bottom  v-else >
      <template v-slot:activator="{on,attrs}">
        <v-chip v-on="on" v-bind="attrs" class="mx-1">

              <v-icon v-if="!fileObject.opened" v-on="on" v-bind="attrs" small left >mdi-shield-lock</v-icon>

            <v-icon small left v-else>mdi-eye</v-icon>
          <span v-if="fileObject.yearAndMonth" v-html="betterYearMonth"></span>
          <span v-else>Monat fehlt</span>
        </v-chip>
      </template>
      <span v-html="fileObject.file.filename"></span><br/>
      <span>Zuletzt geöffnet: <span v-html="lastOpened"></span></span>
    </v-tooltip>

  </div>
</template>

<script>
export default {
  name: "UploadPreviewPayRoll",
  props:{
    fileObject:Object,
    index:Number,
    readonly:Boolean
  },
  data(){
    return {
      menu: false,
    }
  },
  computed:{
    fileObjectC:{
      get(){
        return this.fileObject;
      },set(value){
        //TODO Emit
        this.$emit("update",value)
      }
    },
    lastOpened(){
      let moment=require('moment');
      return moment(this.fileObject.opened).calendar();
    },
    betterYearMonth(){
      let moment=require('moment');
      return moment(this.fileObject.yearAndMonth).format("MMM YY");
    },
    fileSize(){
      let size= this.fileObject.file.size;
      if(size<1000){
        return size+"B";
      }else if(size>=1000 && size<1000000){
        return Math.round(size/1000)+"KB"
      }else if(size>=1000000){
        return Math.round(size/1000000)+"MB"
      }else{
        return size+"B";
      }
    }
  },
  methods:{
    remove(){
      this.$emit("remove",{index:this.index,file:this.fileObject});
    }
  }
}
</script>

<style scoped>

</style>