import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
import Register from './views/Register.vue';
import ConfirmationToken from "@/views/ConfirmationToken";
import RegisterVerification from "@/views/RegisterVerification";
import Logout from "@/views/Logout";
import store from '@/store'

import AdminHome from "@/views/Admin/AdminHome";
import Home from "@/views/Home";
import Chat from "@/views/Chat/Chat";
import CalendarView from "@/views/Calendar/CalendarView";
import UserList from "@/views/User/UserList";
import NewsOverview from "@/views/News/NewsOverview";
import AdminNews from "@/views/Admin/AdminNews";
import AdminNavigation from "@/views/Admin/AdminNavigation";
import AdminUser from "@/views/Admin/AdminUser";
import GroupChat from "@/views/Chat/GroupChat";
import GroupChatList from "@/views/Chat/GroupChatList";
import ChatListUniversal from "@/views/Chat/ChatListUniversal";
import ChatItemUniversal from "@/views/Chat/ChatItemUniversal";
import FormList from "@/views/Forms/FormList";
import PayrollWrapper from "@/views/Payrolls/PayrollWrapper";
import PayrollSite from "@/views/Payrolls/PayrollSite";
import Calendar from "@/views/Calendar/Calendar";
import AdminRoles from "@/views/Admin/AdminRoles";

Vue.use(Router);

function guardMyRoute(to,from,next){
    let isAuthenticated = store.state.auth.status.loggedIn;
    if (to.name !== 'Login' && !isAuthenticated) {
        next('/login');
    }
    else{
        next();
    }
}


export const router = new Router({
    mode: 'history',
    routes: [
        {
            path:"/admin",
            beforeEnter:guardMyRoute,
            component:AdminHome,
            meta: {
                breadCrumb: [
                    {
                        text: 'Admin'
                    }
                ]
            },

            children: [
                {
                  path:'/',
                  component: AdminNavigation,
                  name:'Admin',
                },
                {
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: 'news',
                    component: AdminNews,
                    name:"News",
                    meta: { breadCrumb: 'News' }
                },
                {
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: 'roles',
                    component: AdminRoles,
                    name:"Rollen",
                    meta: { breadCrumb: 'Rollen' }
                },
                {
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: 'news/:id',
                    component: AdminNews,
                    name:"News",
                    props:true,
                    meta: { breadCrumb: 'News' },
                },
                {
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: 'mitarbeiter',
                    component: AdminUser,
                    name:"Mitarbeiter",
                    meta: { breadCrumb: 'Mitarbeiter' }
                },
            ]
        },
        {
          path:"/kalenderOld",
            beforeEnter:guardMyRoute,
          component:CalendarView
        },
        {
            path:"/kalender",
            beforeEnter:guardMyRoute,
            component:Calendar,
            name:"Kalender"
        },
        {
            path:"/kalender/:terminId",
            beforeEnter:guardMyRoute,
            name:"Kalender",
            props:true,
            component:Calendar
        },
        {
          path:"/gehaltsabrechnungen",
            beforeEnter:guardMyRoute,
          component:PayrollWrapper,
            name:"Gehaltsabrechnungen"
        },
        {
          path:"/gehaltsabrechnungen/:tab",
            beforeEnter:guardMyRoute,
          component:PayrollWrapper,
            name:"Gehaltsabrechnungen",
            props:true
        },
        {
          path:"/gehaltsabrechnungen/datei/:id",
            beforeEnter:guardMyRoute,
          component:PayrollSite,
            name:"Gehaltsabrechnung",
            props:true
        },
        {
            path:"/mitglieder",
            beforeEnter:guardMyRoute,
            component:UserList
        },
        {
            path:"/aktuelles",
            beforeEnter:guardMyRoute,
            component:NewsOverview
        },
        {
          path:"/formulare",
            beforeEnter:guardMyRoute,
          component:FormList
        },
        {
            path:"/mitglieder/:id"
        },
        {
            path:"/chat",
            beforeEnter:guardMyRoute,
            component:Chat,
            children: [
                {
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: '/',
                    component: ChatListUniversal,
                    name:"Chats"
                },
                {
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: ':type/:id',
                    name:"activeChat",
                    component: ChatItemUniversal,
                    props: true
                }
            ],
        },

        {
            path: '/',
            name: 'Home',
            beforeEnter:guardMyRoute,
           // beforeEnter:guardMyRoute,
            component: Home
        },

        {
            path: '/login',
            component: Login,
            name:'Login'
        },

        {
            path: '/logout',
            beforeEnter:guardMyRoute,
            component: Logout
        },

        {
            path: '/register',
            component: Register
        },
        {
            path: '/profile',
            beforeEnter:guardMyRoute,
            name: 'Profile',
            // lazy-loaded
            component: () => import('./views/Profile.vue')
        },

        {
            path: '/confirm-account',
            beforeEnter:guardMyRoute,
            name: 'Account Verifizierung',
            component: ConfirmationToken
        },
        {
            path: '/successful-registration',
            beforeEnter:guardMyRoute,
            name: 'Registrierung erfolgreich',
            component: RegisterVerification
        },
        {
            path: '/chat/gruppen',
            beforeEnter:guardMyRoute,
            name: 'GruppenWrapper',
            component: GroupChat,
            props: true,
            children:[
                {
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: 'open/:id',
                    name:"Gruppe",
                    component: ChatItemUniversal,
                    props: true
                },
                {
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: '/',
                    name:"Gruppen",
                    component: GroupChatList,
                },

            ]
        },
    ]
});