<template>
  <v-layout column wrap style="position:fixed;bottom:1em;right:1em;z-index:999">

    <v-alert min-width="200px" v-for="(notification,index) of this.$store.state.notificationsStore.notifications"
             :dark="!notification.type"
             :key="index"
             dismissible
             style="z-index:10"
             class="animate__animated animate__backInUp"
             :class="{'animate__fadeOutRight' : notification.animation,'accent':!notification.type}"
             :type="notification.type"
    >
      <v-row align="center">
        <v-col class="grow">
          <span v-html="notification.text"></span>
          <div v-if="notification.subtext">
            <small v-html="notification.subtext"></small></div>
        </v-col>
        <v-col v-if="notification.action" class="shrink">
          <v-btn small @click="$router.push(notification.action)"><span
              v-html="notification.buttonText"></span></v-btn>
        </v-col>
      </v-row>


    </v-alert>
  </v-layout>
</template>
<script>
export default {
  name: 'AppNotification'
}
</script>
