<template>
<v-list>
  <v-list-item link @click="$router.push('/admin/news')">
    <v-list-item-icon>
      <v-icon>mdi-newspaper</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        Aktuelles
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-icon>
      <v-icon>mdi-chevron-right</v-icon>
    </v-list-item-icon>
  </v-list-item>
  <v-list-item link @click="$router.push('/admin/mitarbeiter')">
    <v-list-item-icon>
      <v-icon>mdi-account-multiple</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        Mitarbeiter
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-icon>
      <v-icon>mdi-chevron-right</v-icon>
    </v-list-item-icon>
  </v-list-item>
  <v-list-item link @click="$router.push('/admin/roles')">
    <v-list-item-icon>
      <v-icon>mdi-account-multiple</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        Rollen
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-icon>
      <v-icon>mdi-chevron-right</v-icon>
    </v-list-item-icon>
  </v-list-item>
</v-list>
</template>

<script>
export default {
  name: "AdminNavigation"
}
</script>

<style scoped>

</style>