<template>
  <v-img :src="image"
  />
</template>

<script>
export default {
  name: "AvatarUser",
  props:{
    file:Object,
    name:String,
  },
  computed:{
    image(){
      if(this.file) return 'data:'+this.file.contentType+';base64,'+this.file.file;
      else return 'https://ui-avatars.com/api/?name='+this.name+"&background=random";
    }
  }
}
</script>

<style scoped>

</style>