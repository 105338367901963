<template>
  <div>
<v-dialog v-model="dialog" max-width="600px" scrollable persistent>
  <template v-slot:activator="{attrs,on}">
    <v-btn v-on="on" v-bind="attrs" plain x-large>
      <v-switch disabled v-model="payrollsSecurity">
        <template v-slot:label>
          <v-icon v-if="payrollsSecurity">mdi-lock</v-icon>
          <v-icon v-else>mdi-lock-open</v-icon>
        </template>
      </v-switch>
    </v-btn>
  </template>
  <v-card v-if="!consentStatus[0]">
    <v-card-title class="text-h5">
      Digitaler Abruf Ihrer Gehaltsabrechnungen.
    </v-card-title>
    <v-card-text>
      <p>
        Damit Sie den Service der digitalen Gehaltsabrechungen nutzen können, benötigen wir Ihre Einverständniserklärung.
      </p>
      <v-dialog v-model="photo" persistent max-width="500px">
        <template v-slot:activator="{attrs,on}">
          <v-checkbox class="checkbox-fix" v-bind="attrs" v-on="on" v-model="consent" label="Ich beantrage hiermit ausdrücklich & stimme gleichzeitig zu, dass ich meine Gehaltsabrechnung in Zukunft digital zur Verfügung gestellt haben möchte."></v-checkbox>
        </template>

        <v-card class="pa-5">
          <WebcamVideo @photo="addPhoto"/>
        </v-card>
      </v-dialog>

     <p>Um die Identifizierung für Ihren zuständigen Personalbearbeiter zu vereinfachen, werden Sie mit setzen den Hakens aufgefordert, ein Selfie von sich hochzuladen.
     </p>
      <small class="pt-5">*falls Sie weiterhin die Gehaltsabrechnungen in Papierform nutzen wollen, können Sie dieses Fenster einfach schließen</small>


    </v-card-text>



    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          color="green darken-1"
          text
          @click="dialog = false"
      >
        Abbrechen
      </v-btn>
      <v-btn
          color="green darken-1"
          text
          @click="upload"
          :disabled="!consent"
      >
        Absenden
      </v-btn>
    </v-card-actions>

  </v-card>
  <v-card v-else-if="!consentStatus[1]">
    <v-card-title class="text-h5">
      Ein weiterer wichtiger Schritt!
    </v-card-title>
    <v-card-text>
      Danke für Ihre Einverständniserklärung. Um den Datenschutz zu gewährleisten, muss Ihre Anfrage nun von Ihrem Personalverantwortlichen geprüft werden.
    </v-card-text>
    <v-card-actions>
      <v-btn @click="dialog=false">Verstanden</v-btn>
    </v-card-actions>
  </v-card>
  <v-card v-else>
    <v-card-title class="text-h5">
      Geschafft!
    </v-card-title>
    <v-card-text>
      Sie bekommen in Zukunft Ihre Gehaltsabrechnungen digital und können diese gesichert in der App öffnen.
    </v-card-text>
    <v-card-actions>
      <v-btn @click="dialog=false">Verstanden</v-btn>
    </v-card-actions>
  </v-card>

</v-dialog>

  </div>
</template>

<script>
import WebcamVideo from "@/views/WebcamVideo";
import ApiService from "@/services/api.service";
import {NotifiyClient} from "@/models/notification";
export default {
  name: "GiveConsent",
  components: {WebcamVideo},
  data(){
    return{
      photo:false,
      photoFile:null,
      payrollsSecurity:true,
      dialog:false,
      consent:false,
      request:null,
      consentStatus:[]
    }
  },
  created() {
    this.checkConsentStatus();
  },
  methods:{
    checkConsentStatus(){
      let self=this;
      ApiService.get("user/payroll/consent/status").then(response => {
        self.consentStatus=response.data;
      })
    },
    upload(){
      let self=this;
      ApiService.post("user/payroll/consent",this.request).then(() => {
        self.$store.dispatch("notificationsStore/add",new NotifiyClient("success","Erfolgreich",false))
        self.checkConsentStatus();
      })
    },
    addPhoto(photo){

      if(photo){
        this.consent=true;
        this.photo=false;
        this.photoFile=photo;
        photo=photo.replace("data:","");
        photo=photo.split(";")[1];
        photo=photo.split(",")[1];
        this.request= {
          id:null,
          userId:null,
          photo:photo
        }

      }




    }
  }
}
</script>

<style scoped>

</style>