<template>
  <v-sheet>
  <v-list-item two-line>
    <v-list-item-avatar>
      <Avatar :name="user.firstName+' '+user.lastName" :file="user.profilePic"/>
    </v-list-item-avatar>
    <v-list-item-content>
    <v-list-item-title>
      <span v-html="user.firstName+' '+user.lastName"></span>
    </v-list-item-title>
    <v-list-item-subtitle>
      möchte seine Gehaltsabrechnungen digital einsehen können.
    </v-list-item-subtitle>

    </v-list-item-content>
    <v-list-item-action>
      <v-btn icon color="success" @click="authenticate"><v-icon>mdi-check</v-icon></v-btn>
    </v-list-item-action>
    <v-list-item-action>
      <v-btn icon color="error"><v-icon>mdi-close</v-icon></v-btn>
    </v-list-item-action>

  </v-list-item>
    <v-divider inset/>
    <div class="px-15" v-if="request">
      Identitätsbestätigung: (Design fehlt)
      <v-img class="rounded"  :src="'data:image/png;base64,'+request.photo" width="100px"></v-img>
    </div>

  </v-sheet>
</template>

<script>
import ApiService from "@/services/api.service";
import Avatar from "@/views/Avatar";
export default {
  name: "PayrollRequest",
  components:{Avatar},
  props:{
    user:Object
  },
  data(){
    return{
      request:null
    }
  },
  created() {
    this.getRequest()
  },
  methods:{
    authenticate(){
      let self=this;
      ApiService.post("user/payroll/authenticate",{content: this.user.id}).then(() => {
        self.$emit("updatedRequest");
      });
    },
    getRequest(){
      let self=this;
      ApiService.get("payroll/request/get/"+this.user.id).then(response => {
        self.request=response.data;
      })
    }
  }
}
</script>

<style scoped>

</style>