<template>
  <v-menu v-model="menu" offset-y :close-on-content-click="false">
    <template v-slot:activator="{attrs,on}">
<v-chip @click:close="remove" close v-bind="attrs" v-on="on"  color="white" class="text--disabled mx-1">
  <span v-if="!fileObject.title" v-html="fileObject.file.filename"></span><span v-else v-html="fileObject.title"></span>
</v-chip>
    </template>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon>
              mdi-file
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-html="fileObject.file.filename"></v-list-item-title>
            <v-list-item-subtitle v-html="fileObject.file.contentType"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-chip small v-html="fileSize"></v-chip>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>

        <v-list-item>
          <v-list-item-content>
            <v-text-field outlined hide-details dense label="Titel" v-model="fileObjectC.title"/>

          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field outlined hide-details dense label="Kategorie" v-model="fileObjectC.category"/>

          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-textarea outlined label="Beschreibung" v-model="fileObjectC.description"/>

          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="menu = false"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "UploadPreview",
  props:{
    fileObject:Object,
    index:Number
  },
  data(){
    return {
      menu: false,
      categories:["Ho"]
    }
  },
  computed:{
    fileObjectC:{
      get(){
        return this.fileObject;
      },
      set(value){
        //TODO emit
        this.$emit("update",value);
      }
    },
    fileSize(){
      let size= this.fileObject.file.size;
      if(size<1000){
        return size+"B";
      }else if(size>=1000 && size<1000000){
        return Math.round(size/1000)+"KB"
      }else if(size>=1000000){
        return Math.round(size/1000000)+"MB"
      }else{
        return size+"B";
      }
    }
  },
  methods:{
    remove(){
      this.$emit("remove",{index:this.index,file:this.fileObject});
    }
  }
}
</script>

<style scoped>

</style>