import axios from 'axios';
import authHeader from "@/services/auth-header";
import host from "@/services/host";
import Notification from "../models/notification";
import store from '../store';

export default function (file) {

    let url=host+'/api/file'
    // You can add checks to ensure the url is valid, if you wish

    const formData = new FormData();
    formData.append("file", file);

    return axios.post(url+"/upload", formData,{headers: authHeader()}).catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //console.log(error.response.status);
            //console.log(error.response.headers);
            store.dispatch("notificationsStore/add", new Notification("error",error.response.data.error,false))

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            store.dispatch("notificationsStore/add", new Notification("error","Server not responding",false))

        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        // console.log(error.config);
    });


}

export function bulkUpload(files){
    let url=host+'/api/file'
    const formData = new FormData();
    formData.append("files",files);

    return axios.post(url+"/upload/bulk", formData,{headers: authHeader()}).catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //console.log(error.response.status);
            //console.log(error.response.headers);
            store.dispatch("notificationsStore/add", new Notification("error",error.response.data.error,false))

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            store.dispatch("notificationsStore/add", new Notification("error","Server not responding",false))

        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        // console.log(error.config);
    });
}
