<template>
<v-container>
  <v-breadcrumbs :items="breadCrumbs"></v-breadcrumbs>
<router-view></router-view>
</v-container>
</template>

<script>
export default {
  name: "AdminHome",
  components: {},
  computed: {
    breadCrumbs() {
      let pathArray = this.$route.path.split("/")
      pathArray.shift()
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          to: breadcrumbArray[idx - 1]
              ? "/" + breadcrumbArray[idx - 1].path + "/" + path
              : "/" + path,
          text: this.$route.matched[idx].meta.breadCrumb || path,
        });
        return breadcrumbArray;
      }, [])
      return breadcrumbs;
    },
  },
}
</script>

<style scoped>

</style>