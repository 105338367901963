<template>
<v-avatar style="border:3px solid white">
  <v-img :src="user.profilePic?'data:image/png;base64,'+user.profilePic.file : 'https://ui-avatars.com/api/?name='+user.firstName+'+'+user.lastName"
  ></v-img>
</v-avatar>
</template>

<script>
export default {
  props:{
    user:Object
  },
  name: "UserAvatar"
}
</script>

<style scoped>

</style>