<template>
<v-layout wrap>
  <v-flex v-for="news of news" :key="news.id" xs12>
    <v-sheet class="ma-1" rounded elevation="1">
      <NewsItem :news="news"/>

    </v-sheet>
    </v-flex>
</v-layout>
</template>

<script>
import NewsItem from "@/views/News/NewsItem";
export default {
  name: "NewsOverview",
  components:{NewsItem},
  data(){
    return{
      allNews:[{
        category:'FAQ',
        title:'TstNews',
        content:'Hallo i boms ein News bitte lese mich du geile Schlange'
      }]
    }
},
  computed:{
    news:{
      get(){
      return this.$store.state.news.all
    },
      deep:true
    }
  }
}
</script>

<style scoped>

</style>