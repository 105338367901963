<template>
  <v-sheet max-height="100%" min-height="100%" height="100%">
    <div style="height: 100%">
      <v-list v-if="currentUser" ref="chat" class="chatHeight overflow-y-auto">
        <v-list-item class="d-flex justify-center">

          <v-btn small text plain @click="loadNextMessages">Mehr Nachrichten laden</v-btn>
        </v-list-item>
        <div class="d-flex align-content-end align-end flex-wrap" style="min-height: 70vh">
          <v-list-item style="max-height: 500px" v-for="(message,index) of $store.state.chat.messages"  :key="message.id" :class="message.senderId == currentUser.id ? 'sent' : 'replies'">
            <v-spacer v-if="message.senderId == currentUser.id && message.messageType != 3"/>
            <!--<v-list-item-avatar width="5px" v-if="message.senderId != currentUser.id">
            <v-img  :src="$store.state.chat.activeContact.profilePic?'data:image/png;base64,'+$store.state.chat.activeContact.profilePic.file : 'https://ui-avatars.com/api/?name='+$store.state.chat.activeContact.firstName+'+'+$store.state.chat.activeContact.lastName"
                    :alt=$store.state.chat.activeContact.username />
          </v-list-item-avatar>
            -->
            <MessageItem :current-user="currentUser" :index="index" :message="message"
                         :message-time="messageTime(message.timestamp)"/>


          </v-list-item>
        </div>
        <div v-if="loadingMessages" class="mx-auto d-flex justify-center">
          <v-progress-circular size="15" indeterminate></v-progress-circular>
        </div>
      </v-list>
      <v-sheet max-width="100%"  class="rounded-t pb-0 bt-2 px-4">
        <v-layout>
          <v-flex class="mr-1 mb-1" xs12>
            <v-text-field counter maxlength="250" :rules="maxCharacters" @click:prepend-inner="emojiDialog=!emojiDialog" prepend-inner-icon="mdi-emoticon" hide-details placeholder="Message" solo   v-model="newMessage" @keypress.enter="sendMessageUniversal(newMessage)">
              <template v-slot:append>
                <v-btn disabled icon class="ml-2" v-if="$store.state.chat.connectionStatus">
                  <v-icon > mdi-file </v-icon>
                  <input type="file"
                         class="file-input"
                         style="opacity:0;width:100%;height:100%;position: absolute;top:0;left:0"

                         @change="setPDF"
                         accept=".pdf"
                  />
                </v-btn>
                <v-btn disabled icon class="mr-2" v-if="$store.state.chat.connectionStatus">
                  <v-icon > mdi-camera </v-icon>
                  <input type="file"
                         class="file-input"
                         style="opacity:0;width:100%;height:100%;position: absolute;top:0;left:0"

                         @change="setImage"
                         accept="image/png, image/jpeg,image/bmp"
                  />
                </v-btn>
                <v-icon color="success" @click="sendMessageUniversal(newMessage)" v-if="$store.state.chat.connectionStatus"> mdi-send </v-icon>

                <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">

                      <v-progress-circular indeterminate size="16"></v-progress-circular><v-icon color="warning" > mdi-alert</v-icon>
                    </div>
                  </template>
                  You are offline :( Reconnecting...
                </v-tooltip>
              </template>

            </v-text-field>
          </v-flex>

        </v-layout>
      </v-sheet>
    </div>
    <v-dialog width="325px" v-model="emojiDialog">
      <VEmojiPicker @select="insert" />
    </v-dialog>
    <ImageCropper :has-image="img!=null" :img="img" @updateImage="upload"></ImageCropper>
  </v-sheet>
</template>

<script>
import {VEmojiPicker} from 'v-emoji-picker';
import {findChatMessagesPagable, findGroupChatMessagesPagable} from "@/services/socket.api";
import {loadContacts} from "@/services/chat.service";
import Notification from "@/models/notification";
import MessageItem from "@/views/Chat/MessageItem";
import ImageCropper from "@/views/ImageCropper";
import {getAsByteArray, getFileSize} from "@/services/file.service";

export default {
  name: "ChatItemUniversal",
  props: {
    id: String,
    type:String
  },
  components: {ImageCropper, MessageItem, VEmojiPicker},
  beforeRouteUpdate() {
    /** just use `this`
    if (this.$store.state.chat.groupChats.length > 0) {
      //console.log(this.$store.state.chat.groupChats[0])
      this.$store.state.chat.activeContact = this.$store.state.chat.groupChats[this.$store.state.chat.groupChats.findIndex(x => x.id === this.id)];
    } else {
      this.$store.state.chat.activeContact = {name: 'loading'}
    }
    */
  },
  data() {
    return {
      //TODO Get Name of
      img: null,
      pdf: null,
      group: null,
      loadingMessages: false,
      emojiDialog: false,
      newMessages: [],
      newMessage: "",
      loadedMessagesPage: 1,
      maxCharacters: [
        value => value.length <= 250 || 'Max 250 characters',
      ],
      maxFileSize2: [
        files => !files || !files.some(file => file.size > 2e6) || 'Die Datei darf nicht größer als 2MB sein!'
      ]
    }
  },
  watch: {
    '$store.state.chat.messages'() {
      this.$nextTick(() => {
        this.scrollLastMessageIntoView();
      });
    }
  },

  created() {
    this.loadingMessages = true;
    this.$store.dispatch("chat/loadActiveUser", {id: this.id, isGroup: this.isGroup}).then(response => {
      if (this.isGroup) {
        this.$store.state.chat.isGroup = true;
        this.findGroupMessagesByPage(this.loadedMessagesPage);
      } else {

        this.findMessagesByPage(this.loadedMessagesPage);
      }
      return response;
    },error=>{
      console.error(error);
      this.$router.back();
    });
    this.$nextTick(() => {
      this.scrollLastMessageIntoView();
    });
    this.$store.dispatch("chat/loadAllChats", this.currentUser.currentChatPartners);
  },
  beforeDestroy() {
    this.$store.state.chat.isGroup = false;
  },
  methods: {

    insert(emoji) {
      this.newMessage += emoji.data;
    },
    scrollLastMessageIntoView() {
      let objDiv = this.$refs.chat.$el
      objDiv.scrollTop = objDiv.scrollHeight;
    },
    loadNextMessages() {
      this.loadedMessagesPage++;
      if (this.$store.state.chat.isGroup) {
        this.findGroupMessagesByPage(this.loadedMessagesPage);
      } else {
        this.findMessagesByPage(this.loadedMessagesPage);
      }
    },

    findMessagesByPage(page) {

      if (this.$store.state.chat.activeContact === undefined || !this.$store.state.chat.activeContact) return;
      findChatMessagesPagable(this.$store.state.chat.activeContact.id, this.currentUser.id, page).then((msgs) => {
            this.$store.dispatch("chat/messages", msgs);
            this.loadingMessages = false;
            loadContacts();
          }
      );
    },
    findGroupMessagesByPage(page) {

      findGroupChatMessagesPagable(this.id, page).then((msgs) => {

            this.$store.dispatch("chat/messages", msgs);
            this.loadingMessages = false;
            loadContacts();
          }
      );
    },
    createBase64Image: function (FileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.img = event.target.result;
        this.sendMessageUniversal("", this.img, null, 1);
        this.img = null
      }
      reader.readAsDataURL(FileObject);
    },
    createBase64PDF: function (FileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.pdf = event.target.result;
        this.sendMessageUniversal("", event.target.result, FileObject.name, 2);
      }
      reader.readAsDataURL(FileObject);
    },
    upload(file) {
      this.createBase64Image(file);
    },
    setImage: function (e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      else this.img = URL.createObjectURL(files[0]);
    },
    setPDF: function (e) {

      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      else {
        //this.pdf = URL.createObjectURL(files[0]);
        getAsByteArray(files[0]).then((response) => {
          const sizeInMB = response.length / 1048576;
          console.log("Dateigröße:", sizeInMB);
           this.createBase64PDF(files[0]);
        });
      }

    },

    sendMessageUniversal(msg, file, fileName, messageType) {
      let isGroup = this.isGroup;
      if (file) {
        let fileSize = getFileSize(file);
        if (fileSize > 2) {
          this.$store.dispatch("notificationsStore/add", new Notification("error", "Datei zu groß", false, fileSize + "/2MB"))
        }
      }
      //console.log(fileSize);

      if (!this.$store.state.chat.connectionStatus) {
        this.$store.dispatch("notificationsStore/add", new Notification("error", "You are offline. Please try to refresh.", false))
        return false;
      }
      const message = {
        senderId: this.currentUser.id,
        senderName: this.currentUser.firstName + ' ' + this.currentUser.lastName,
        image: file,
        fileName: fileName,
        messageType: messageType,
        content: msg,
        timestamp: new Date(),
      };
      if (isGroup) {
        message.groupId = this.id;
        this.$store.state.chat.stompClient.send("/app/group/message", {}, JSON.stringify(message));

      } else {
        message.recipientId = this.$store.state.chat.activeContact.id;

        message.recipientName = this.$store.state.chat.activeContact.name;
        this.$store.state.chat.stompClient.send("/app/chat", {}, JSON.stringify(message));

      }

      this.newMessages = [...this.$store.state.chat.messages];
      this.newMessages.push(message);
      this.$store.dispatch("chat/messages", this.newMessages);
      this.newMessage = "";
    },
  messageTime(time) {
    let moment = require('moment'); // require
    return moment(time).fromNow();
  }
  },
  computed:{
    isGroup(){
      return this.type==='gruppe';
    },
    currentUser(){
      return this.$store.state.auth.user;
    }
  }
}
</script>

<style scoped>
.chatHeight{
  height: calc(100vh - 60px - 60px - 10px)!important;
}
@media screen and (max-width: 600px) {
  .chatHeight{
    height: calc(100vh - 60px - 60px - 60px)!important;
  }
}

</style>