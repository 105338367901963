<template>
  <v-list-item-content :class="{ 'text-right':message.senderId == currentUser.id}">
    <div class="d-flex justify-center" v-if="message.messageType===3">
      <v-chip class="mx-auto" x-small ><span v-if="message.senderId != currentUser.id" v-html="message.senderName+' hat'" class="mr-1"></span><span v-else class="mr-1">Du hast </span><span v-html="message.content"></span></v-chip>
    </div>
    <div v-else>
    <small v-if="message.senderId!=currentUser.id" class="font-weight-thin" v-html="message.senderName"></small>

    <div v-if="index<$store.state.chat.messages.length-1" :class="{ 'justify-end':message.senderId == currentUser.id}"
         class="d-flex ">

      <v-sheet class="px-1 py-2 text-secondary" rounded :color="messageColor" style="max-width: 100%;">
        <ChatImageViewer v-if="message.messageType===1" :message="message"/>
      <PDFViewer v-else-if="message.messageType===2" :name="message.fileName"  :url="message.image" width="100px" height="auto"></PDFViewer>
      <span v-else
          style="word-break: break-all;">{{message.content}}</span>
      </v-sheet>
    </div>
    <div ref="last" v-else :class="{ 'justify-end':message.senderId == currentUser.id}" class="d-flex">
      <v-sheet class="px-1 py-2  text-secondary" :color="messageColor" rounded style="max-width: 100%;">
        <ChatImageViewer v-if="message.messageType===1" :message="message"/>
        <PDFViewer v-else-if="message.messageType===2" :name="message.fileName" :url="message.image" width="100px" height="auto"></PDFViewer>

      <span v-else
          style="word-break: break-all;">{{message.content}}</span></v-sheet>
    </div>
      <small style="font-size: 0.7rem" class="font-weight-thin" v-html="messageTime"></small>
</div>

  </v-list-item-content>
</template>
<script>
import PDFViewer from "@/views/PDFViewer";
import ChatImageViewer from "@/views/Chat/ChatImageViewer";
export default {
  name: 'MessageItem',
  components:{ChatImageViewer, PDFViewer},
  props: {
    index: {},
    message: {}
  },
  //TODO Klick on image fullscreen
  computed:{
    messageTime(){
      let moment = require('moment'); // require
      return moment(this.message.timestamp).fromNow();
    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    messageColor(){
      if(this.currentUser.id==this.message.senderId){

        return 'accent lighten-4'
      }else{
        return 'grey'
      }
    }
  }
}
</script>
<style scoped>
@media screen and (max-width: 600px) {
}

</style>