<template>
  <v-list-item @click="startConf" ref="bd" class="px-2 my-2"
               color="white"
               :class="{'no-interactions':!birthdayIsToday}"
               style="background-color: white;"

  >

    <v-list-item-avatar>
      <Avatar :file="user.profilePic" :name="user.firstName+' '+user.lastName"/>

    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title v-html="birthday"></v-list-item-title>
      <v-list-item-subtitle v-html="user.firstName+' '+user.lastName"></v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon v-if="birthdayIsToday"><v-icon small>mdi-cake</v-icon></v-list-item-icon>
  </v-list-item>
</template>

<script>
import moment from 'moment';
import Avatar from "@/views/Avatar";
const party = require("party-js")
export default {
  components: {Avatar},
  props:{
    user:Object
  },
  name: "UserBirthday",
  methods:{
    startConf(e){
      if(this.birthdayIsToday){
        party.confetti(e.target);

      }

    }
  },
  computed:{
    birthdayIsToday(){
      return moment(this.user.birthday).format("DD.MM")===moment().format("DD.MM");
    },
    birthday(){
      moment.locale('de');
      return moment(this.user.birthday).format("Do MMMM");
    }
  }
}
</script>

<style scoped>

</style>