<template>
  <div  class="mx-2 d-flex justify-start align-center" style="width:100%">
    <v-avatar size="45px">
      <v-img

          class="rounded"
          id="profile-img"
          :src="imageReactive"
      >

      </v-img>


      <input v-if="!activeChatPartner.email" type="file"
             class="file-input"
             style="opacity:0;width:100%;height:100%;position: absolute;top:0;left:0"
             @change="updateImage"
             accept="image/png, image/jpeg,image/bmp"
      />
    </v-avatar>
    <v-list-item link @click="showGroupInfo=!showGroupInfo">

      <v-list-item-title class="text-truncate" v-html="activeChatPartner.name"></v-list-item-title>
    </v-list-item>
    <v-spacer class="flex-grow-1"></v-spacer>
    <v-dialog
        v-if="!activeChatPartner.email"
        v-model="addParticipants"
        fullscreen
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-account-multiple-plus-outline</v-icon>
        </v-btn>
      </template>
      <SelectParticipants @stepBack="close" @updateParticipants="updateParticipants" :participants-ids="activeChatPartner.participantsIds" :group-id="activeChatPartner.id"/>
    </v-dialog>
    <v-menu
        v-if="!activeChatPartner.email"
        bottom
        left
        offset-y
        transition="slide-y-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn

            icon
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item link @click="leaveGroupChatRoom()" color="danger"
        >
          <v-list-item-title>Verlassen</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <GroupInfo @close="close" v-if="$store.state.chat.activeContact" :show="showGroupInfo" :room="$store.state.chat.activeContact"/>
    <ImageCropper :has-image="img!=null" :img="img" @updateImage="uploadImage"/>
  </div>
</template>
<script>
import SelectParticipants from "@/views/Chat/CreateNewGroup/SelectParticipants"
import UploadFile from "@/services/upload-file";
import {deleteFile} from "@/services/file.service";
import {leaveGroupChatRoom, sendGroupMessage, updateGroupChatRoom} from "@/services/chat.service";
import GroupInfo from "@/views/Chat/GroupInfo";
import ImageCropper from "@/views/ImageCropper";

export default {
  name: 'ChatItemTopBar',
  components: {SelectParticipants,GroupInfo,ImageCropper},
  props: {
  },
  computed:{

    activeChatPartner(){
      return this.$store.state.chat.activeContact
    },
    imageReactive(){
      if(this.activeChatPartner) {
        if(this.activeChatPartner.profilePic){
          return this.activeChatPartner.profilePic ? 'data:image/png;base64,' + this.activeChatPartner.profilePic.file : 'https://ui-avatars.com/api/?name=' + this.activeChatPartner.name;

        }
        else return this.activeChatPartner.image ? 'data:image/png;base64,' + this.activeChatPartner.image.file : 'https://ui-avatars.com/api/?name=' + this.activeChatPartner.name;
      }
      return null;
    },
  },
  data(){
    return{
      showGroupInfo:false,
      img:null,
      addParticipants:false,
      hasImage:false
    }
  },
  methods:{
    leaveGroupChatRoom(){
      leaveGroupChatRoom(this.$store.state.chat.activeContact.id);
    },
    updateParticipants(userId){
      let index= this.activeChatPartner.participantsIds.indexOf(userId);
      if(index!=-1){
        this.activeChatPartner.participantsIds.splice(index,1);
      }else{
        this.activeChatPartner.participantsIds.push(userId);
      }

    },
    uploadImage(image){
      UploadFile(image).then(response => {
        let fileToDelete=null;
        if( this.$store.state.chat.activeContact.image && this.$store.state.chat.activeContact.image.id){
          fileToDelete = this.$store.state.chat.activeContact.image;
        }
        this.$store.state.chat.activeContact.image=response.data;
        let self=this;
        updateGroupChatRoom(this.$store.state.chat.activeContact).then(room =>{
          self.$store.state.chat.activeContact=room;
          if(fileToDelete) deleteFile(fileToDelete);
        })
        sendGroupMessage(this.$store.state.chat.activeContact.id," das Gruppenbild aktualisiert.",null,null,"3")


      });

      this.$store.dispatch("loading/success");
      this.img=null;
    },
    updateImage: function (e) {
      this.hasImage = true
      let files = e.target.files || e.dataTransfer.files;
      if(!files.length) return;
      else this.img = URL.createObjectURL(files[0]);

    },
    close(){
      this.addParticipants=false;
      this.showGroupInfo=false;
    },
  }
}
</script>
