import AuthService from '../services/auth.service';
import ApiService from "@/services/api.service";
import store from "@/store/index";
import {NotifiyClient} from "@/models/notification";
import {updateUser} from "@/services/user.service";
import axios from "axios";
import UploadFile from "@/services/upload-file";

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user}
    : { status: { loggedIn: false }, user: null};


export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        toggleStatus({commit}){
            store.dispatch("loading/toggle")
            return ApiService.get("user/status/toggle").then(
                response => {
                    commit('toggleStatus',response.data);
                    store.dispatch("loading/success")
                },
                error =>{
                    console.error(error);
                    store.dispatch("notificationsStore/add",new NotifiyClient("warning","Fehler",false,"Status konnte nicht geändert werden. Versuche es bitte erneut."))
                    store.dispatch("loading/error")
                });
        },
        updateLocalUser({commit},user){
            commit('updateLocalUser',user)
        },
        login({ commit }, user) {

            return AuthService.login(user).then(
                user => {

                    commit('loginSuccess', user);

                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        loginCognito({ commit }, tokenObject) {

            return AuthService.loginCognito(tokenObject).then(
                user => {
                    commit('loginSuccess', user);

                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                user => {
                    commit('loginSuccess', user.data);
                    return Promise.resolve(user);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        uploadImagePlaceholder({commit},user){

            axios.get("https://ui-avatars.com/api/?name="+user.firstName+"+"+user.lastName).then(response => {
                //self.imgFile=response.data;

                let fileUrl= URL.createObjectURL(response.data);

                UploadFile(fileUrl).then(response2 => {
                    commit("uploadImagePlaceholder",response2.data);
                })
            })
        }
    },
    mutations: {
        toggleStatus(state,status){
            state.user.here=status;
            let user= JSON.parse(localStorage.getItem("user"))
            user.here=status;
            console.log("Userhere",user);
            localStorage.setItem('user', JSON.stringify(user));
        },
        updateLocalUser(state,user){
            localStorage.setItem('user', JSON.stringify(user));
            state.user=user;
        },
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            localStorage.setItem('user', JSON.stringify(user));
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        uploadImagePlaceholder(state,data) {
            state.user.profilePic=data;
            updateUser( state.user);
        }
    }
};