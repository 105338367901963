<template>
  <v-app id="app" style="overflow-x:hidden">
    <ModalIdle v-if="isIdle" />
<!-- IOS PWA INSTALL PROMPT  -->
    <PWAPrompt :permanentlyHideOnDismiss="false"/>

    <LeftDrawer v-if="$vuetify.breakpoint.mdAndUp"/>
    <Topbar/>
<v-main class="pt-lg-2">

  <v-banner
      color="info"
      v-if="deferredPrompt"
      style="position:absolute;top:0;left:0;width:100vw;z-index:9999"
      dark
      class="text-left"
  >

    Install our APP for better experience!

    <template v-slot:actions>
      <v-btn text @click="dismiss">Dismiss</v-btn>
      <v-btn text @click="install">Install</v-btn>
    </template>
  </v-banner>
  <!--<v-img width="1000px" :src="require('./assets/logo.png')" style="pointer-events: none;position: absolute;bottom:0;right: -100px;opacity: 0.02"></v-img>
    -->
  <v-layout fill-height>
    <v-flex xs12>
    <v-container fluid style="min-height: 100%;">
      <router-view @scrollTop="scrollTop"/>
    </v-container>
    </v-flex >
    </v-layout>


  <AppNotification/>

  <v-dialog
      v-if="currentUser"
      v-model="currentUser.changePasswordOnNextLogin"
      persistent
  >
    <v-card>
      <v-card-title class="text-h5">
        Bitte ändere dein Passwort.
      </v-card-title>
      <v-card-text>
        <v-container>
        <p>Mit diesen Daten kannst du dich nun jederzeit im Elephant Kundenportal einloggen.</p>
       <v-form v-model="valid">
        <v-text-field label="EMail" placeholder="Email" disabled v-model="currentUser.email"></v-text-field>

          <v-text-field label="Neues Passwort" placeholder="Neues Passwort" type="password" :rules="passwordRules" v-model="newPassword"></v-text-field>
       </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            :disabled="!valid"
            @click="changePassword"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</v-main>
    <RightDrawer v-if="$vuetify.breakpoint.mdAndUp"/>

    <Mobilebar class="hidden-md-and-up"/>
    </v-app>
</template>

<script>
import Mobilebar from "@/views/Navigation/Mobilebar";
import PWAPrompt from 'vue2-ios-pwa-prompt';
import {changePassword} from "@/services/user.service";
import {connect} from "@/services/chat.service";
import LeftDrawer from "@/views/Navigation/LeftDrawer";
import RightDrawer from "@/views/Navigation/RightDrawer";
import Topbar from "@/views/Navigation/Topbar";
import AppNotification from "@/views/AppNotification";
import ModalIdle from "@/views/ModalIdle";
//TODO Add Frontend to see Processes and Projects; And remove old DME STuff
export default {
  name: 'App',
  components: {AppNotification, Topbar, RightDrawer, LeftDrawer, Mobilebar, PWAPrompt,ModalIdle},
  data(){
    return{
      heightNav:null,
      deferredPrompt:null,
      newPassword:"",
      valid:false,
      passwordRules:
          [
            v => !!v || this.$t('login.passwordRequired'),
            value => {
              const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
              return (
                  pattern.test(value) ||
                  "Min. 8 characters with at least one capital letter, a number and a special character."
              )}
          ]
    }
  },

  created() {
    let self=this;
    if(this.currentUser) {
      connect();
      this.installPWA();
      //this.loadContacts();
      //if(this.currentUser) this.registerFirebase();
      this.$store.dispatch("app/getAllUsers");
      this.$store.dispatch("app/getAllUsersBirthday");
      this.$store.dispatch("app/getAllUsersOnline");
      this.$store.dispatch("news/getAllNews");
      this.$store.dispatch("forms/getAll");
      this.$store.dispatch("chat/loadAllChats", this.currentUser.currentChatPartners);
      this.$store.dispatch("payroll/countMine");
      this.$store.dispatch("payroll/getMyPayrolls");

      setInterval(function () {
        self.$store.dispatch("app/getAllUsersOnline");
      }, 300000);
    }
  },
  computed:{
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    isMobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        // true for mobile device
        return true
      }else{
        // false for not mobile device
        return false
      }
    },
  currentUser(){
    return this.$store.state.auth.user;
  },
    dark(){
      return this.$vuetify.theme.dark;
    }
  },
  mounted () {

    this.$store.state.app.initialHistory=window.history.length;
  },
  methods:{
    changePassword(){
      changePassword(this.newPassword);

      //this.currentUser.changePasswordOnNextLogin=false;
    },
    scrollTop(){
      this.$nextTick(() => {
        this.$refs.mainContent.scrollTop = 0;
      });
    },
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    installPWA(){
      console.log("PWA Install?")
      window.addEventListener("beforeinstallprompt", e => {
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        console.log("Prompt "+e);
      });
      window.addEventListener("appinstalled", () => {
        console.log("app installed");
        this.deferredPrompt = null;
      });

    },

    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  }
}
</script>

