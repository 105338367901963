<template>
<v-list-item style="position:relative" @click="openPayroll" class="white mb-2" two-line link>

  <v-list-item-content>
  <v-list-item-title class="text-truncate " :class="{'font-weight-bold' : !payroll.opened}" v-html="betterYearAndMonth">

  </v-list-item-title>
  <v-list-item-subtitle class="text-truncate" v-html="payroll.file.filename" >

  </v-list-item-subtitle>
  </v-list-item-content>
  <v-tooltip left style="position: absolute;top:0;right:0;">
    <template v-slot:activator="{attrs,on}">
      <v-icon class="pa-1" small color="success" v-on="on" v-bind="attrs">mdi-shield-lock</v-icon>
    </template>
    Diese Datei liegt verschlüsselt auf unserem Server.
  </v-tooltip>
</v-list-item>
</template>

<script>
export default {
  name: "PayrollItem",
  props:{
    payroll:Object
  },
  computed: {
    betterYearAndMonth() {
      let moment = require('moment');
      return moment(this.payroll.yearAndMonth).format("MMM YY");
    },

  },
  methods:{
    openPayroll(){
      this.$router.push("/gehaltsabrechnungen/datei/"+this.payroll.id);
    }
  }
}
</script>

<style scoped>

</style>