<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="open"

  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          v-bind="attrs"
          fab
          v-on="on"
      ><v-icon>mdi-plus</v-icon></v-btn>
    </template>
    <v-card class="">
      <v-card-title class="grey">Neue Rolle erstellen</v-card-title>
      <v-form class="px-5 py-5">
        <v-text-field outlined label="Technischer Name" v-model="role.name" placeholder="Technischer Name" />
        <v-text-field outlined label="Anzeige Name" v-model="role.display" placeholder="Anzeige Name" />
        </v-form>
      <v-card-actions>
        <v-btn :loading="$store.state.loading.loadingState" @click="save" text>Veröffentlichen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AdminRoleAddNew",
  data(){
    return{
      role:{
        id:null,
        name:null,
        display:null
      }
    }
  },
  methods:{
    save(){
      this.$emit("saveRole",this.role);
    }
  }
}
</script>

<style scoped>

</style>