<template>
  <v-dialog content-class="overflow-hidden" fullscreen max-width="100%" style="max-height: 100%;" v-model="hasImageC">
    <v-card>
      <v-card-actions>
        <v-btn text @click="hasImageC=false">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="crop"  :loading="$store.state.loading.loadingState">Upload</v-btn>
      </v-card-actions>

      <cropper
          ref="cropper"
          class="upload-example-cropper"
          :src="img"
          style="width:100%; height:100%"
          :stencil-props="{
		handlers: {},
		movable: false,
		scalable: false,
	}"
          :stencil-size="{
		width: 280,
		height: 280
	}"
          image-restriction="stencil"
          :stencil-component="$options.components.Stencil"


      />

    </v-card>
  </v-dialog>
</template>

<script>
import Compressor from "compressorjs";
import Notification from "@/models/notification";

export default {
  name: "ImageCropper",
  props:{
    img:String,
    hasImage:Boolean
  },
  computed:{
    hasImageC:{
      get(){
        return this.hasImage;
      },set(value){
        //TODO Emit
        this.$emit("update",value)
      }
    }
  },
  methods:{
    crop() {
      this.$store.dispatch("loading/toggle",true);
      const result = this.$refs.cropper.getResult();
      this.compressImage(this.dataURItoBlob(result.canvas.toDataURL()));

    },

    dataURItoBlob(dataURI) {
// convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
      else
        byteString = unescape(dataURI.split(',')[1]);
// separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
// write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], {type:mimeString});
    },
    compressImage(file){
      let self=this;
      new Compressor(file, {
        quality: 0.1,
        width:720,

        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          self.changeImage(result)
        },
        error(err) {
          console.error(err.message);
          self.setSnack({
            message: "Fehler beim Komprimieren Ihrer Datei. Bitte verwenden Sie ein anderes Bild",
            color: "error"
          })
        },
      });
    },
    changeImage(image){
      let self=this;

      if(image.size>1000000){
        self.$store.dispatch("notificationsStore/add",new Notification("error","Datei zu groß "+Math.round(image.size/1000000)+" /1MB. Versuchen Sie das Bild zu verkleinern",false));
        self.$store.dispatch("loading/error");
      }
      else {

          this.$emit('updateImage',image);
          this.$store.dispatch("loading/success");
      }

    },
  }
}
</script>

<style scoped>

</style>