<template>
<v-bottom-navigation
    app
    shift
    v-if="!this.$vuetify.breakpoint.mdAndUp"
>
  <v-progress-linear v-if="$route.name==='Welcome'" style="position:absolute;left:0;top:0;width:100%;z-index:99999" color="accent" v-model="progress"></v-progress-linear>


  <v-btn v-for="(item,index) of items" :key="index" @click="$router.push(item.link)"><v-icon v-html="item.icon"></v-icon><span v-html="item.title"></span></v-btn>

</v-bottom-navigation>
</template>

<script>
export default {
  name: "MobileBar",
  methods:{
    handleLogout:function(){
      this.$store.dispatch('auth/logout')
      this.$router.push("/")
    },
  },
  data(){
    return{
      items: [
        { title: 'Dashboard', icon: 'mdi-home-outline',link:'/' },
        { title: 'Kalender', icon: 'mdi-calendar' ,link:'/kalender'},
        { title: 'Mitglieder', icon: 'mdi-account-group-outline' },
        { title: 'Nachrichten', icon: 'mdi-message-outline',link:'/chat' },
        { title: 'Aktuelles', icon: 'mdi-information-outline' },
        //{ title: 'Events', icon: 'mdi-gift-outline' },
        //{ title: 'Formulare', icon: 'mdi-folder-outline' },
      ],
    }
  },
  computed:{
    page:{
      get() {
        return this.$store.state.app.welcome.page
      },
      set(value){
        this.$store.state.app.welcome.page=value;
      }
    },
    pages(){
      return this.$store.state.app.welcome.pages
    },
    progress(){
      return ((this.page)/(this.pages))*100;
    },
    currentUser(){
      return this.$store.state.auth.user;
    }
  }
}
</script>

<style scoped>

</style>