<template>
  <v-tabs-items v-model="$store.state.navigation.payrollTabs.selected">
    <v-tab-item>
      <UploadPayroll v-for="user of $store.state.payroll.authenticatedUsers" :user="user" :key="user.id"/>
    </v-tab-item>
    <v-tab-item>
      <div v-if="$store.state.payroll.requests">
      <PayrollRequest  @updatedRequest="load" v-for="user of $store.state.payroll.requests" :user="user" :key="user.id"/>
      </div>
      <div v-else class="caption text-center mx-auto my-5">zurzeit keine Anfragen</div>
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
import UploadPayroll from "@/views/Payrolls/UploadPayroll";
import PayrollRequest from "@/views/Payrolls/PayrollRequest";
export default {
  name: "PayrollWrapper",
  props:{
    tab:String
  },
  components: {PayrollRequest, UploadPayroll},
  created(){
    //this.$store.dispatch("payroll/getMyPayrolls");

    this.load();



  },
  mounted() {
    this.$nextTick(() => {
      if(this.tab!=null){
        this.$store.dispatch("navigation/changeTab",Number.parseInt(this.tab));

      }
    })
    },
  methods:{
    load(){
      this.$store.dispatch("payroll/getRequests");
      this.$store.dispatch("payroll/getAuthenticatedUsers");
    }
  }
}
</script>

<style scoped>

</style>