<template>
  <div>
  <v-list>
  <v-list-item  v-for="chat of chats" :key="chat.id" @click="$router.push('/chat/gruppen/open/'+chat.id)">
    <v-list-item-avatar width="45px" height="45px" class="rounded-circle">

      <v-img :src="chat.image?'data:image/png;base64,'+chat.image.file : 'https://ui-avatars.com/api/?name='+chat.name"
      ></v-img>

    </v-list-item-avatar>
    <v-list-item-content>

      <v-badge
          v-if="chat.unreadMessages>0"
          bordered
          color="success"
          :content="chat.unreadMessages"
          overlap
          offset-y="15px"
          offset-x="20px"
      >
      <v-list-item-title v-html="chat.name"></v-list-item-title>
      </v-badge>

      <v-list-item-title v-else v-html="chat.name"></v-list-item-title>
      <v-list-item-subtitle><span v-if="chat.lastMessage"><span v-if="chat.lastMessage.senderId!=currentUser.id" v-html="chat.lastMessage.senderName.split(' ')[0]+': '"></span><span v-else>Du: </span><span v-html="chat.lastMessage.content"></span></span><span v-else >-</span></v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon>
      <v-icon>mdi-chevron-right</v-icon>
    </v-list-item-icon>
  </v-list-item>
  <v-list-item v-if="chats.length<=0">
    <v-list-item-content>
      <v-list-item-title>Du hast im Moment keine Gruppen.</v-list-item-title>
    </v-list-item-content>
  </v-list-item>

</v-list>
  <CreateChatRoom/>
  </div>
</template>

<script>

import CreateChatRoom from "@/views/Chat/CreateChatRoom";
export default {
  name: "GroupChatList",
  components: { CreateChatRoom},
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    chats(){
      return this.$store.state.chat.groupChats;
    },

  }
}
</script>

<style scoped>

</style>