<template>
  <div>
    <h3 class="text-center">Meine Lohnabrechungen</h3>
    <div class="d-flex justify-center">
      <GiveConsent v-if="$store.state.payroll.countMine<=0 && consentStatus!=[1,1]"/>
      <v-list color="transparent" max-width="100%" v-else>
      <PayrollItem  v-for="payroll of sortedPayrollsByOpenedAndYearAndMonth" :key="payroll.id" :payroll="payroll"></PayrollItem>
      </v-list>
    </div>
    <!---->

  </div>
</template>
<script>
import GiveConsent from "@/views/Payrolls/Request/GiveConsent"
import ApiService from "@/services/api.service";
import PayrollItem from "@/views/Payrolls/PayrollItem";

export default {
  name: 'PayrollHomeSheet',
  components: {PayrollItem, GiveConsent},
  data(){
    return{
      consentStatus:[0,0],
    }
  },
  computed:{
    sortedPayrollsByOpenedAndYearAndMonth(){
      return [...this.$store.state.payroll.all].sort(function(a,b) {
        // nulls sort after anything else
        if (a.opened === null) {
          return -1;
        }
        if (b.opened === null) {
          return 1;
        }
        if(a.yearAndMonth && b.yearAndMonth) {
          a = a.yearAndMonth.split("-");
          b = b.yearAndMonth.split("-");
          return new Date(a[0], a[1], 1) - new Date(b[0], b[1], 1)
        }
    });
    }
  },
  created() {
    this.checkConsentStatus();
  },
  methods: {
    checkConsentStatus() {
      let self = this;
      ApiService.get("user/payroll/consent/status").then(response => {
        self.consentStatus = response.data;
      })
    },
  }
}
</script>
