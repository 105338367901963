<template>
<v-card>
  <v-toolbar>
    <v-btn v-if="creating" icon @click="stepBack"><v-icon>mdi-arrow-left</v-icon></v-btn>
    <v-btn v-else icon @click="stepBack"><v-icon>mdi-close</v-icon></v-btn>
    <v-list-item v-if="!search">
      <v-list-item-content>
        <v-list-item-title ><span v-if="creating">Neue Gruppe</span><span v-else>Mitglieder hinzufügen</span></v-list-item-title>
        <v-list-item-subtitle>Mitglieder auswählen</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-text-field solo flat clearable autofocus v-model="searchString" dense hide-details v-else placeholder="Suchen..."></v-text-field>
    <v-spacer/>
    <v-btn icon @click="search=!search"><v-icon v-if="!search">mdi-magnify</v-icon><v-icon v-else>mdi-magnify-close</v-icon></v-btn>
  </v-toolbar>
  <div class="pa-4 pb-0">
    <v-avatar class="mr-2"  width="45px" height="45px">

      <v-img :src="currentUser.profilePic?'data:image/png;base64,'+currentUser.profilePic.file : 'https://ui-avatars.com/api/?name='+currentUser.firstName+'+'+currentUser.lastName"
      ></v-img>

    </v-avatar>
    <v-badge

        class="mr-2"
        v-for="user of participants"
        :key="user.id"
        color="error"
        icon="mdi-close"
        overlap
        bottom
        style="cursor: pointer"
        @click.native="updateParticipants(user.id)"

    >
      <v-avatar  width="45px" height="45px">

        <v-img :src="user.profilePic?'data:image/png;base64,'+user.profilePic.file : 'https://ui-avatars.com/api/?name='+user.firstName+'+'+user.lastName"
        ></v-img>

      </v-avatar>
    </v-badge>
    <v-divider class="mt-4"></v-divider>
  </div>
  <v-list>
    <v-list-item link v-for="user of searchedParticipants" @click="updateParticipants(user.id)" :key="user.id">
      <v-list-item-avatar width="45px" height="45px" class="rounded-circle" :class="{'unread':user.newMessages>0}">

        <v-img :src="user.profilePic?'data:image/png;base64,'+user.profilePic.file : 'https://ui-avatars.com/api/?name='+user.firstName+'+'+user.lastName"
        ></v-img>

      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="user.firstName+' '+user.lastName"></v-list-item-title>
        <v-list-item-subtitle v-html="user.email"></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
  <v-btn v-if="!creating" @click="update" fab style="position:fixed; right:15px;bottom:15px" color="success"><v-icon>mdi-check</v-icon></v-btn>
</v-card>
</template>

<script>
import {NotifiyClient} from "@/models/notification";

export default {
  name: "SelectParticipants",
  props:{
    participantsIds: Array,
    creating:Boolean,
    groupId:String
  },
  data(){
    return{
      search:false,
      searchString:""
    }
  },

  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    searchedParticipants(){

        return this.$store.state.app.allUsers.filter(x => {


          let name=x.firstName+' '+x.lastName;
          if((name.includes(this.searchString) || x.email.includes(this.searchString)) && x.id!=this.currentUser.id){
            return true;
          }
        });
      },
   participants(){
     return this.$store.state.app.allUsers.filter(x=> this.participantsIds.includes(x.id) && x.id!=this.currentUser.id);
   }
  },
  methods:{
    stepBack(){
      this.$emit('stepBack');
    },
    updateParticipants(userId){
      this.$emit('updateParticipants',userId);
    },
    update(){
      let self=this;
      this.$store.dispatch("chat/addChatRoomParticipants",{groupId: this.groupId,participantsToAdd: this.participantsIds}).then(() => {
        self.$store.dispatch("notificationsStore/add", new NotifiyClient("success","Erfolgreich",false))
      })

    }
  }
}
</script>

<style scoped>

</style>