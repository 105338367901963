import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './auth.module';
import {loading} from './loading.module';
import {notificationsStore} from "@/store/notifications.module";
import {processes} from "@/store/processes.module";
import {app} from "./app.module";
import {project} from "./project.module";
import {chat} from "./chat.module";
import {news} from "./news.module";
import {forms} from "./forms.module";
import {payroll} from "@/store/payroll.module";
import {navigation} from "@/store/navigation.module";
import {calendar} from "@/store/calendar.module";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        loading,
        processes,
        notificationsStore,
        app,
        project,
        chat,
        forms,
        news,
        payroll,
        navigation,
        calendar
    }
});