import Vue from 'vue'
import IdleVue from "idle-vue";
import App from './App.vue'
import { router } from './router';
import store from './store';
import VeeValidate from 'vee-validate';
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify.js' // path to vuetify.js export
import "@/scss/global.scss"
import VueI18n from 'vue-i18n';
import messages from './plugins/VueI18n';
import "hover.css";
import 'vue-advanced-cropper/dist/style.css';
import { Cropper } from 'vue-advanced-cropper';
import { Vue2InteractDraggable } from 'vue2-interact'
import Vue2Editor from "vue2-editor";
import 'animate.css';
Vue.use(Vue2Editor);
Vue.use(Vue2InteractDraggable);
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 900000, // 3 seconds
  startAtIdle: false
});
//$2a$10$5h.sYaBh.RDOZVc8tdtv7uAsE.rrKQtasX9TMSpFBO/jrHz4rlHui

Vue.use(Cropper);
Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(VeeValidate);
Vue.config.productionTip = false

Vue.prototype.$host = "http://localhost:8080"



const i18n = new VueI18n({
  locale: 'de',
  messages
});


new Vue({
  render: h => h(App),
  router,
  vuetify,
  i18n,
  store,
  }).$mount('#app')
