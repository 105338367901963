<template>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header color="grey" class="pa-0 px-1">
          <template v-slot:default="{ open }">
          <v-list-item :three-line="open">
            <v-list-item-avatar size="75" :class="{'myGlower' : user.here}">

              <Avatar :file="user.profilePic" :name="user.firstName+'+'+user.lastName"/>
              </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="user.firstName+' '+user.lastName">

              </v-list-item-title>
              <v-list-item-subtitle v-html="user.department">

              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="open" ><v-icon small>mdi-door</v-icon><span v-if="user.room" v-html="user.room"></span><span v-else>kein Raum</span></v-list-item-subtitle>
            </v-list-item-content>
            <!--<v-list-item-action>
              <v-sheet color="white" rounded>
                <v-btn icon tile rounded><v-icon>mdi-message-outline</v-icon></v-btn>
              </v-sheet>
            </v-list-item-action>
            -->
          </v-list-item>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="px-1 pt-4 py-2" color="grey">
          <v-layout justify-space-between wrap style="gap:10px">
            <v-flex shrink class="text-body-2 d-flex align-center">
              <v-icon small class="mr-1">mdi-cake</v-icon><span v-html="birthday"></span>
            </v-flex>
            <v-flex shrink class="text-body-2 d-flex align-center">
              <v-icon small class="mr-1">mdi-phone-outline</v-icon><span v-if="user.telephone" v-html="user.telephone"></span><span v-else>-</span>
            </v-flex>
            <v-flex shrink class="text-body-2 d-flex align-center">
              <v-icon small class="mr-1">mdi-printer-pos</v-icon><span v-if="user.fax" v-html="user.fax"></span><span v-else>-</span>
            </v-flex>
            <v-flex shrink class="text-body-2 d-flex align-center">
              <v-icon small class="mr-1">mdi-email</v-icon><span class="text-truncate" v-html="user.email">0847908ß9</span>
            </v-flex>
          </v-layout>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import Avatar from "@/views/Avatar";
export default {
  name: "UserItem",
  components: {Avatar},
  props:{
    user:Object
  },
  computed:{
    birthday(){
      let moment = require('moment');
      moment.locale('de');
      return moment(this.user.birthday).calendar();
    }
  }
}
</script>

<style scoped>

</style>