<template>

  <v-dialog
      transition="dialog-bottom-transition"
      fullscreen
      v-model="show"
  >
    <template v-if="!speedDial" v-slot:activator="{ on: menu, attrs }">
      <v-tooltip left>
        <template  v-slot:activator="{ on: tooltip }">
      <v-btn
          v-if="!speedDial"
          fab
          fixed
          right
          bottom
          :style="{right:createButtonLeft+'px',bottom:createButtonBottom+'px'}"
          color="primary"
          v-bind="attrs"
          v-on="{ ...tooltip, ...menu }"
      ><v-icon>mdi-plus</v-icon></v-btn>
      <v-btn
          v-else
          color="accent"
          @click.prevent="()=>{return true}"
          fab
          small
          v-bind="attrs"
          v-on="{ ...tooltip, ...menu }"
      >
        <v-icon>mdi-account-multiple-plus</v-icon>
      </v-btn>
        </template>
        Neue Gruppe erstellen
      </v-tooltip>
    </template>
    <SelectParticipants v-if="step==0" creating @stepBack="stepBack" :participants-ids="chatRoom.participantsIds" @updateParticipants="updateParticipants"/>
    <ApplyGroupInfo v-else-if="step==1" @updateImage="updateImage" @stepBack="stepBack" :name="chatRoom.name" @updateName="updateName" :image="chatRoom.image" :participants-ids="chatRoom.participantsIds"/>
    <v-btn v-if="step<1" :disabled="chatRoom.participantsIds<=0" @click="step+=1" fab fixed right bottom>
      <v-icon>mdi-arrow-right</v-icon>
    </v-btn>
    <v-btn :disabled="!chatRoom.name && chatRoom.name===''" v-else @click="create" color="success" fab fixed right bottom>
      <v-icon>mdi-check</v-icon>
    </v-btn>
  </v-dialog>
</template>

<script>
import SelectParticipants from "@/views/Chat/CreateNewGroup/SelectParticipants";
import ApplyGroupInfo from "@/views/Chat/CreateNewGroup/ApplyGroupInfo";
export default {
  name: "CreateChatRoom",
  components: {ApplyGroupInfo, SelectParticipants},
  props:{
    speedShow:Boolean,
    speedDial:Boolean
  },
  data(){
    return{
      show:false,
      chatRoom:{
        name:'',
        //TODO Select participants
        participantsIds:[],
        image:null
      },
      step:0
    }
  },
  watch:{
    speedShow(){
      this.show=this.speedShow
    }
  },
  computed:{
    createButtonLeft(){
      if(this.$vuetify.breakpoint.mdAndUp){
        return 275;
      }else return 15;
    },
    createButtonBottom(){
      if(this.$vuetify.breakpoint.mdAndUp){
        return 15;
      }else return 75;
    }
  },
  methods:{
    stepBack(){
      if(this.step===0){
        this.show=false;
      }else {
        this.step += -1;
      }
    },
    updateImage(imageFile){
      this.chatRoom.image=imageFile;
    },
    updateParticipants(userId){
      let index= this.chatRoom.participantsIds.indexOf(userId);
      if(index!=-1){
        this.chatRoom.participantsIds.splice(index,1);
      }else{
        this.chatRoom.participantsIds.push(userId);
      }

    },
    updateName(name){
      this.chatRoom.name=name;

    },
    create(){
      this.$store.dispatch("chat/createGroupChat",this.chatRoom);
      this.show=false;
    }
  }
}
</script>

<style scoped>

</style>