import   store from "@/store"
import {NotificationsSound} from "@/services/sound.service";
import { router } from '@/router'
import ApiService from "@/services/api.service";
import {countNewMessages, findChatMessage} from "@/services/socket.api";
import Notification, {NotifiyClient} from "@/models/notification";
import {getFileSize} from "@/services/file.service";
import * as StompJs from "@stomp/stompjs";

export function sendGroupMessage(chatId,msg,file,fileName,messageType){

    if(file){
        let fileSize=getFileSize(file);
        if(fileSize > 2){
            store.dispatch("notificationsStore/add",new Notification("error","Datei zu groß",false,fileSize+"/2MB"))
        }
    }

    if(!store.state.chat.connectionStatus){
        store.dispatch("notificationsStore/add",new Notification("error","You are offline. Please try to refresh.",false))
        return false;
    }
        const message = {
            senderId: store.state.auth.user.id,
            senderName: store.state.auth.user.firstName+' '+store.state.auth.user.lastName,
            groupId:chatId,
            image:file,
            fileName:fileName,
            messageType:messageType,
            content: msg,
            timestamp: new Date(),
        };
        store.state.chat.stompClient.send("/app/group/message", {}, JSON.stringify(message));

        store.state.chat.messages.push(message)

}

export function loadContacts(){
    store.dispatch("loading/toggle",true);
    ApiService.post("user/getAllByIds",{ids:store.state.auth.user.currentChatPartners}).then((users) => {

        users.data.map((contact) =>
            countNewMessages(contact.id, store.state.auth.user.id).then((count) => {
                contact.newMessages = count;
                return contact;
            })
        );
        store.dispatch("chat/chatPartners",users.data);
        store.dispatch("loading/success");
        findLastMessages();
    });
}
export function findLastMessages(){

    for(let user of store.state.chat.currentChatPartners) {
        ApiService.post("user/messages/last", {senderId: store.state.auth.user.id, recipientId: user.id}).then(response => {

            user.lastMessage = response.data;
        });

    }
}
export async function getUpdatedCurrentUser(){
    ApiService.get("user/get?id="+store.state.auth.user.id).then(response => {

        ApiService.post("user/update",response.data).then(response2 => {
            let user =JSON.parse(localStorage.getItem("user"));
            let merged = {...user, ...response2.data};
            store.dispatch("auth/updateLocalUser",merged);
            store.dispatch("loading/success");
            loadContacts();
        });
    });

}




//WEBSOCKET
export function connect(){
    if(!store.state.auth.user){
        return null;
    }
    const Stomp = StompJs.Stomp;
    let SockJS = require("sockjs-client");
    SockJS = new SockJS(process.env.VUE_APP_HOST+"/ws");
    store.dispatch("chat/connect",Stomp.over(SockJS));
    store.state.chat.stompClient.reconnect_delay = 5000;
    store.state.chat.stompClient.connect({}, onConnected, onError);

}

function onConnected(){
    store.dispatch("chat/changeConnectionStatus",true);
    console.log("connected");
    store.state.chat.stompClient.subscribe(
        "/user/" + store.state.auth.user.id + "/queue/messages",
        onMessageReceived
    );
}

function onError(err){
    console.log("Error in Websocket!");
    console.log(err);
    store.dispatch("chat/changeConnectionStatus",false);
    console.log('STOMP: ' + err);

    setTimeout(connect(), 10000);
    console.log('STOMP: Reconecting in 10 seconds');
}
function onMessageReceived(msg){

    console.log("Received Message",msg)

    const notification = JSON.parse(msg.body);

    const active = store.state.chat.activeContact

    const isGroup = store.state.chat.isGroup;



    if ((active && active.id == notification.senderId && router.currentRoute.name==='activeChat') || isGroup) {
        findChatMessage(notification.id).then((message) => {
            const newMessages = store.state.chat.messages;
            newMessages.push(message);
            NotificationsSound();
            store.dispatch("chat/messages",newMessages);
            /**if(isGroup) store.dispatch("chat/loadGroupChats");
            else loadContacts();
             */
            store.dispatch("chat/loadAllChats",store.state.auth.user.currentChatPartners);
        });
    } else {
        store.dispatch("notificationsStore/add",new NotifiyClient(null,notification.senderName+":",false,notification.content));
        NotificationsSound();
        getUpdatedCurrentUser();
        store.dispatch("chat/loadAllChats",store.state.auth.user.currentChatPartners);

        console.log("Received a new message in other Chat",notification);
    }


}
export function updateGroupChatRoom(room){
    return ApiService.post("chatroom/update",room).then(response => {
        return Promise.resolve(response.data);
    })
}

export function leaveGroupChatRoom(roomId){
    return ApiService.post("chatroom/leave",{groupId:roomId}).then(() => {
        router.push("/chat");
        store.dispatch("notificationsStore/add",new NotifiyClient("success","Erfolgreich ausgetreten",false))
        return Promise.resolve();
        /**return store.dispatch("chat/loadActiveUser",{id:roomId,isGroup:true}).then(roomOrUser => {

            return Promise.resolve(roomOrUser);

        });
*/

    })
}
