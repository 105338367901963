export default class Termin {

    constructor(title="",beschreibung="",immoId=null,type=null,color="",start="",end="",location="",gaeste=[],notizen="",userId,id,timed=true) {
        this.title = title;
        this.immoId=immoId;
        this.beschreibung = beschreibung;
        this.color = color;
        this.start = start;
        this.end = end;
        this.location = location;
        this.notizen = notizen;
        this.userId = userId;
        this.id = id;
        this.gaeste=gaeste;
        this.type=type;
        this.timed=timed;


    }

}