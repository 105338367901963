<template>
  <div style="width:100%;" class="mt-1">
    <v-row class="fill-height">
      <v-col>
        <v-sheet v-if="small" height="64">
          <v-toolbar
              flat
          >
            <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
            >
              Heute
            </v-btn>

            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>

            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon v-if="calendar.type==='day'">mdi-calendar</v-icon>
              <v-icon v-if="calendar.type==='month'">mdi-calendar-month</v-icon>
              <v-icon v-if="calendar.type==='week'">mdi-calendar-week</v-icon>
              <v-select

                  light

                  v-model="calendar.type"
                  :items="[{
          text:'Monat',
          value: 'month'},
          {
          text:'Woche',
          value: 'week'},
          {
          text:'Tag',
          value: 'day'}]"
                  dense
                  outlined
                  hide-details
                  class="ma-2 shrink"
                  style="width:150px;opacity:0;position:absolute;width:100%;"
              ></v-select>
            </v-btn>
          </v-toolbar>
        </v-sheet>
        <v-sheet :height="calendarHeight" style="position:relative">
      <v-calendar
          style="width: 100%; height:100%;"
          :event-height="eventHeight"
          color="transparent"
          ref="calendar"
          v-model="$store.state.calendar.value"
          :weekdays="weekday"
          :type="$store.state.navigation.calendar.type"
          :events="filteredEvents"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
      >

        <template v-slot:event="{ event }">
          <v-layout :ref="'event'+event.id" justify-space-between class="px-1 v-event-draggable">
            <v-flex xs9>
              <div class="text-truncate">{{ event.title }}</div>
            </v-flex>
            <v-flex xs3 align-self-center>
              <div class="d-flex flex-wrap justify-center align-center" v-if="event.timed" style="font-size: 0.4rem;"><div style="line-height: 7px;">{{ formatEventTime(event.start) }} -</div><div style="line-height: 7px;margin-right:2px">{{ formatEventTime(event.end) }}</div></div>
            </v-flex>
          </v-layout>
        </template>

      </v-calendar>
      <TerminDetails v-if="selectedEvent" @updateSelectedEvent="updateSelectedEvent"
                    :selected-element="selectedElement" :host="selectedEvent.userId === currentUser.id" :selected-event="selectedEvent" :selected-open-prop="selectedOpen"/>
          <AddTermin/>
        </v-sheet>
      </v-col>
    </v-row>


  </div>
</template>

<script>
import moment from "moment";
import ApiService from "@/services/api.service"
import TerminDetails from "@/views/Calendar/TerminDetails";
import AddTermin from "@/views/Calendar/AddTermin";

export default {
  name: "CalendarView",
  components: {TerminDetails,AddTermin},
  props:{
    small:Boolean,
    terminId:String,

    eventHeight:Number,
    calendarHeight:{
      type:String,
      default:'80vh'
    },

  },
  data: () => ({
    mode: 'stack',
    selectedEvent:null, //Das selectierte Event
    selectedElement:null, //Das HTml Element des selectierten Events
    selectedOpen: false,  //Toggle Event Details
    weekday: [0, 1, 2, 3, 4, 5, 6]
  }),
  computed:{
    calendar:{
      get(){
        return this.$store.state.navigation.calendar;
      },
      set(calendar){
        this.$store.state.navigation.calendar=calendar;
      }
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    filteredEvents:{
      get() {
        return this.$store.state.calendar.termine;
      },
      set(termine){
        this.$store.state.calendar.termine=termine
      }
      },
  },
  watch:{
    terminId(){
        this.openTermin();
    },
  },
  created() {
    this.$nextTick(() => {
      this.$store.state.calendar.ref=this.$refs.calendar;
    })

    this.getAllEvents();
  },
  mounted(){
    if(this.terminId && this.terminId!=null) this.openTermin();
  },
  methods: {
    viewDay ({ date }) {
      this.$store.state.calendar.value = date
      this.$store.state.navigation.calendar.type = 'day'
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    updateSelectedEvent(event){
      this.selectedEvent=event;
      this.$store.dispatch("calendar/getAllEvents");
    },
    openTermin(){
      let self=this;
      self.selectedOpen=false;
      ApiService.post("calendar/termin/find",{content: this.terminId}).then(response => {
        self.value=moment(response.data.start).format("YYYY-MM-DD");

        setTimeout(() => {self.selectedEvent=response.data; self.selectedElement=self.$refs["event"+self.terminId]
          self.selectedOpen=true;},200)


      });
    },
    getAllEvents(){
     this.$store.dispatch("calendar/getAllEvents");
    },
    setToday () {
      this.$store.state.calendar.value = ''
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },

    formatEventTime(date) {
      return moment(date).format("HH:mm");
    },

    getEventColor (event) {
      if(!event.color) event.color="black";
      if(event.userId===this.currentUser.id) return event.color
        return event.color;
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  },
}
</script>

<style scoped>
</style>