<template>
  <v-sheet rounded class="my-2 py-2" style="position: relative">

    <div v-if="$store.state.auth.user.roles.includes('ROLE_ADMIN')">
    <v-btn v-if="!editMode" @click="editMode=true;collapsed=false" small icon style="position:absolute;top:15px;right:15px"><v-icon small>mdi-pencil</v-icon></v-btn>
    <v-btn v-else @click="editMode=false" small icon style="position:absolute;top:15px;right:15px"><v-icon small>mdi-pencil-remove</v-icon></v-btn>
    </div>
      <div :class="{ 'text-red' : newsReactive.category === 'FAQ', 'text-grey' : newsReactive.category === 'Information'}" class="mx-3 d-flex align-center">

      <v-icon v-if="newsReactive.category==='Information'" large>mdi-information</v-icon>
      <v-icon color="#AE2627" v-else-if="newsReactive.category==='FAQ'" large>mdi-help-circle</v-icon>
    <span v-html="newsReactive.category"></span>
    </div>
  <v-list-item three-line>

    <v-list-item-content >
      <v-list-item-title  @input="onInputTitle(newsReactive,$event)"  :contenteditable="editMode" >{{newsReactive.title}}</v-list-item-title>
      <div >
        <div v-if="collapsed">
          <div v-if="newsReactive.content">
          <small v-html="newsReactive.content.substr(0,200)"></small><small v-if="newsReactive.content.length>200">...</small>
          </div>
        </div>
        <div v-else>
          <small class="richImageResize">
            <p v-if="newsReactive.content" :contenteditable="editMode" @input="changeDescription" v-html="newsReactive.content"></p>
            <span v-html="formattedCreatedAt"></span>
          </small>
        </div>
      </div>


    </v-list-item-content>
  </v-list-item>

    <v-btn @click="save" :loading="$store.state.loading.loadingState" v-if="editMode" class="px-2 mx-4" text x-small>Änderungen speichern</v-btn>
    <v-btn v-else-if="collapsed" @click="collapsed=false" class="px-2 mx-4" text x-small>mehr lesen</v-btn>
    <v-btn v-else-if="!collapsed" @click="collapsed=true" class="px-2 mx-4" text x-small>weniger lesen</v-btn>
  </v-sheet>
</template>

<script>
export default {
  props:{
    news:Object,
  },
  data(){
    return{
      titleTmp: this.news.title,
      contentTmp:this.news.content,
      collapsed:true,
      editMode:false
    }
  },
  computed:{
    newsReactive:{
      get() {
        return this.news;
      },deep:true
    },
    formattedCreatedAt(){
      let moment = require('moment');
      moment.locale('de');
      if(this.news.createdAt && !this.news.updatedAt){
        return moment(this.news.createdAt).calendar();
      }
      else if(this.news.createdAt && this.news.updatedAt){
        return moment(this.news.createdAt).calendar()+'<span class="font-italic"> editiert: '+moment(this.news.updatedAt).calendar()+"</span>";
      }
      else return null;
    }
  },
  methods:{
    onInputTitle(news,e){
     /** news.title=e.target.textContent;
      console.log(e.target.textContent);
      this.$store.dispatch("news/updateSpecific",news);
      console.log(this.$store.state.news.all[this.$store.state.news.all.findIndex(x => x.id === news.id)].title);
    */
     this.titleTmp=e.target.textContent;
      },
    changeDescription(e){
      this.contentTmp=e.target.innerText;
    },
    save(){
      let news = this.news;

      news.content=this.contentTmp;
      news.title=this.titleTmp;
      this.$store.dispatch("loading/toggle",true)
      this.$store.dispatch("news/save",news).then(()=>{
       this.open=false;
      });

    }
  },
  name: "NewsItem"
}
</script>

<style scoped>
.text-red{
  color:#AE2627;
}
.text-grey{
  color:#8BA3AF;
}
</style>