<template>
  <v-layout wrap fill-height>
    <v-list width="100%">
      <template v-for="role of roles">
      <v-list-item :key="role.id" two-line>
        <v-list-item-content>
        <v-list-item-title v-html="role.display"></v-list-item-title>
        <v-list-item-subtitle v-html="role.name"></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon><v-icon>mdi-delete</v-icon></v-btn>
        </v-list-item-action>
      </v-list-item>
        <v-divider :key="role.id"></v-divider>
      </template>
    </v-list>
    <div style="position:absolute;bottom:15px;right:15px;">
      <AdminRoleAddNew @saveRole="save"/>
    </div>
  </v-layout>
</template>

<script>
import ApiService from "@/services/api.service"
import AdminRoleAddNew from "@/views/Admin/AdminRoleAddNew";
export default {
  name: "AdminRoles",
  components: {AdminRoleAddNew},
  data(){
    return{
      roles:[]
    }
  },
  created() {
    this.getRoles();
  },
  methods:{
    save(role){
      let self=this;
      ApiService.post("role/create",role).then(response => {
        self.roles.push(response.data);
      })
    },
    getRoles(){
      let self=this;
      ApiService.get("role/list/all").then(response => {
        self.roles=response.data;
      })
    }
  }
}
</script>

<style scoped>

</style>