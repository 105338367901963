<template>
  <div>
  <v-img @click="overlay=true"  max-width="100px" :src="message.image"></v-img>

  <v-overlay
      @click="overlay = false"
      :value="overlay"
  >
    <v-img max-width="50%"
           class="mx-auto"
        :src="message.image"
    >
    </v-img>
  </v-overlay>
  </div>
</template>

<script>
export default {
  name: "ChatImageViewer",
  data(){
    return{
      overlay:false
    }
  },
  props:{
    message:Object,
  }
}
</script>

<style scoped>

</style>